/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import Typography from "../../components/Typography";

const FlexContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 15,
  backgroundColor: "white",
});

// const Icon = styled('img')({
//   marginRight: '8px', // Adjust as needed
// });

const Icon = styled("img")(({ theme }) => ({
  width: "15px",
  margin: 15,
  position: "relative",
  "&:hover": {
    animation: "none",
    transform: "rotate(360deg)",
    transition: "transform 0.5s",
  },
}));

function FAQAccordion() {
  const { t } = useTranslation();

  const iconUrl = "/icons/arrow.png";

  return (
    <Box sx={{ mb: 5, maxWidth: 800 }}>
      <Typography
        variant="h4"
        sx={{ my: 5, textAlign: "center", color: "warning.main" }}
      >
        {t("partners.faq.title")}
      </Typography>
      {t("partners.faq.questions", { returnObjects: true }).map(
        (item, index) => (
          <Accordion key={index} sx={{ bgcolor: "primary.dark" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "secondary.main" }} />}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  flexGrow: 1,
                  color: "white",
                }}
              >
                {item.question}
              </Typography>
              <Divider />
            </AccordionSummary>
            <FlexContainer>
              <Icon src={iconUrl} alt="icon" />
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "primary.dark",
                }}
              >
                {item.answer}
              </Typography>
            </FlexContainer>
          </Accordion>
        ),
      )}
    </Box>
  );
}

export default FAQAccordion;
