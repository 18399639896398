import { styled, Typography } from "@mui/material";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2",
  position: "relative",
  zIndex: 4,
}));

const CircleSpan = styled("span")(({ theme, position }) => ({
  position: "relative",
  top: 0,
  width: "20px",
  height: "20px",
  border: "1px solid #0f0f1e",
  borderRadius: "50%",
  ...(position === "left" && { marginRight: "15px" }),
  ...(position === "right" && { marginLeft: "15px" }),
  "&::after": {
    content: '""',
    position: "absolute",
    top: "-5px",
    left: "-5px",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: theme.palette.warning.main,
    zIndex: -1,
  },
}));

export default function TitleWithCircles({ children }) {
  return (
    <StyledTitle variant="h6">
      <CircleSpan position="left" />
      {children}
      <CircleSpan position="right" />
    </StyledTitle>
  );
}
