import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container, Box } from "@mui/material/";
import { motion } from "framer-motion";
import Typography from "../../components/Typography";
import FeaturedList from "./FeaturedList";
import TiersComponent from "./TiersComponent";
import Count from "./Countup";

export default function Buy() {
  const { t, i18n } = useTranslation();
  const controls = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 },
  };
  const bg = "rgba(124, 174, 229, 0.3)";
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: -10,
        alignItems: "center",
        // bgcolor: bg,
        overflow: "hidden",
        pt: 18,
        pb: 2,
        color: "primary.dark",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={controls}
        transition={{ duration: 0.5 }}
      >
        <Typography component="h2" variant="h2" marked="center" align="center">
          MARINE SURVEY PRO
        </Typography>
      </motion.div>
      <FeaturedList />
      <Container maxWidth="md">
        <Count t={t} />
        <TiersComponent />
      </Container>
    </Container>
  );
}
