import * as React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { styled } from "@mui/material/styles";
import { CONTACTS } from "../../../CONTACTS";

const SocialIconButton = styled(IconButton)`
  color: #000000;
`;
export default function SocialLinks() {
  const onClick = (href) => {
    window.open(href, "_blank");
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <SocialIconButton onClick={() => onClick(CONTACTS.insta)}>
        <InstagramIcon />
      </SocialIconButton>
      <SocialIconButton onClick={() => onClick(CONTACTS.youtube)}>
        <YouTubeIcon />
      </SocialIconButton>
      <SocialIconButton onClick={() => onClick(CONTACTS.telegram)}>
        <TelegramIcon />
      </SocialIconButton>
    </Stack>
  );
}
