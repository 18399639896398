exports.CONTACTS = {
  name: "Marine Survey",
  slogan: "Your on-the-go marine knowledge toolbox",
  slogan2: {
    en: "Personal Marine assistant in your pocket",
    ua: "Особистий морський помічник у вашій кишені",
  },
  email: "support@mybridgehelper.com",
  phone: "23123",
  address: "sdasdasd",
  insta:
    "https://www.instagram.com/marinesurveypro/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
  youtube: "https://www.youtube.com/channel/UCxbrD9YSViTUz9YueUjyavw",
  google:
    " https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey",
  googlePro:
    "https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey_pro",
  apple: "https://apps.apple.com/ua/app/marine-survey-pro/id6458737068",
  appleFree:
    "https://apps.apple.com/tr/app/marine-survey-assistant/id6475769290",
  telegram: "https://t.me/marinesurveypro",
  req: {
    ua: {
      1: "ФОП Патрича Дмитро Анастасійович",
      2: "Податковий номер:  2280412597",
      3: "р/р:  UA14 322001 00000 26005340016583",
      4: "Банк:  АТ «УНІВЕРСАЛ БАНК»  (MONOBANK)",
      5: "МФО: 322001",
      6: "Тел.: +38(098) 999-15-03",
      7: "Email: patrychadima@gmail.com",
    },
    en: {
      1: "SOLP Patrycha Dmytro",
      2: "Tax number:  2280412597",
      3: "Account number:  UA14 322001 00000 26005340016583",
      4: "Bank:  JSC «UNIVERSAL BANK»  (MONOBANK)",
      5: "MFO: 322001",
      6: "Phone: +38(098) 999-15-03",
      7: "Email: patrychadima@gmail.com",
    },
  },
};

const commonFeatures = {
  en: [
    {
      title: "Port Log Book: ",
      price: "Free",
      items: ["Port Log Book entries", "Bearthing operations", "Date events"],
    },
    {
      title: "Offline Marine Calculators:",
      items: [
        { name: "Unit Converter", price: "- Free" },
        { name: "Stowage Plan", price: "- Free" },
        { name: "List Calculator", price: "- Free" },
        { name: "Interpolation", price: "- Free" },
        { name: "Humidity", price: "- Free" },
        { name: "Slip", price: "- Free" },
        { name: "ETA", price: "- Free" },
        { name: "Draft Survey", price: "- $11.99" },
        { name: "Ballast Calculator", price: "- $8.99" },
        { name: "Fuel Oil", price: "- $9.99" },
        { name: "Cargo Trimming", price: "- $9.99" },
        { name: "Squad & UKC", price: "- $6.99" },
        { name: "ME Power", price: "- $6.99" },
        { name: "Change Draft/GM", price: "- $3.99" },
        { name: "Remove ADs", price: "- $1.99" },
      ],
    },
    {
      title: "IALA Buoyage System: ",
      price: "Free",
      items: ["All information"],
    },
    {
      title: "Offline Video Courses: ",
      price: "$18.99",
      items: ["Draft Survey", "Program Draft Survey", "Grain Stability "],
    },
    {
      title: "Offline Useful Content: ",
      price: "Free",
      pricePro: "PRO",
      items: [
        "Draft Survey Theory",
        "Helicopter Operations",
        "Intact / Grain Stability",
        "Medical First Aid",
        "Fire Control and LSA",
        "Manuals",
        "Other Calculations",
        "Other useful content",
        "Country Flags",
      ],
    },
  ],
  ua: [
    {
      title: "Портовий вахтовий журнал: ",
      price: "Безкоштовно",
      items: [
        "Записи портового вахтового журналу",
        "Операції швартування",
        "Події за датами",
      ],
    },
    {
      title: "Офлайн морські калькулятори:",
      items: [
        { name: "Конвертер одиниць", price: "- Безкоштовно" },
        { name: "План розміщення вантажу", price: "- Безкоштовно" },
        { name: "Калькулятор крену", price: "- Безкоштовно" },
        { name: "Інтерполяція", price: "- Безкоштовно" },
        { name: "Вологість", price: "- Безкоштовно" },
        { name: "Ковзання", price: "- Безкоштовно" },
        { name: "Час прибуття", price: "- Безкоштовно" },
        { name: "Драфт-сюрвей", price: "- $11.99" },
        { name: "Калькулятор баласту", price: "- $8.99" },
        { name: "Паливо", price: "- $9.99" },
        { name: "Тримування вантажу", price: "- $9.99" },
        { name: "Просідання і запас води під кілем", price: "- $6.99" },
        { name: "Потужність головного двигуна", price: "- $6.99" },
        { name: "Зміна осадки/метацентричної висоти", price: "- $3.99" },
        { name: "Прибрати рекламу", price: "- $1.99" },
      ],
    },
    {
      title: "Система плавучої навігаційної обстановки МАМС: ",
      price: "Безкоштовно",
      items: ["Вся інформація"],
    },
    {
      title: "Офлайн відеокурси: ",
      price: "$18.99",
      items: ["Драфт-сюрвей", "Програма драфт-сюрвею", "Зернова остійність"],
    },
    {
      title: "Офлайн корисний контент: ",
      price: "Безкоштовно",
      pricePro: "PRO",
      items: [
        "Теорія драфт-сюрвею",
        "Вертолітні операції",
        "Остійність неушкодженого судна / Зернова остійність",
        "Перша медична допомога",
        "Протипожежний контроль та рятувальні засоби",
        "Посібники",
        "Інші розрахунки",
        "Інший корисний контент",
        "Прапори країн",
      ],
    },
  ],
};

exports.Tiers = {
  en: [
    {
      isPro: false,
      title: "MARINE SURVEY",
      price: "0",
      features: {
        ...commonFeatures.en,
      },
      buttons: [
        {
          buttonText: "try free on android",
          link: "https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey",
        },
        {
          buttonText: "try free on ios",
          link: "https://apps.apple.com/tr/app/marine-survey-assistant/id6475769290",
        },
      ],
    },
    {
      isPro: true,
      title: "MARINE SURVEY PRO",
      price: "29.99",
      features: {
        ...commonFeatures.en,
      },
      buttons: [
        {
          buttonText: "try Pro on Android",
          link: "https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey_pro",
        },
        {
          buttonText: "try Pro on IOS",
          link: "https://apps.apple.com/ua/app/marine-survey-pro/id6458737068",
        },
      ],
    },
  ],
  ua: [
    {
      isPro: false,
      title: "MARINE SURVEY",
      price: "0",
      features: {
        ...commonFeatures.en,
      },
      buttons: [
        {
          buttonText: "Спробувати безкоштовну версію на android",
          link: "https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey",
        },
        {
          buttonText: "Спробувати безкоштовну версію на  ios",
          link: "https://apps.apple.com/tr/app/marine-survey-assistant/id6475769290",
        },
      ],
    },
    {
      isPro: true,
      title: "MARINE SURVEY PRO",
      price: "29.99",
      features: {
        ...commonFeatures.en,
      },
      buttons: [
        {
          buttonText: "Спробувати PRO версію на Android",
          link: "https://play.google.com/store/apps/details?id=com.ssdevelop.marine_survey_pro",
        },
        {
          buttonText: "Спробувати PRO версію на IOS",
          link: "https://apps.apple.com/ua/app/marine-survey-pro/id6458737068",
        },
      ],
    },
  ],
};

exports.products = {
  en: [
    { id: -3, title: "Marine crewing", new: true },
    { id: -2, title: "Margram", new: true },
    { id: -1, title: "Marine News", new: true },
    { id: 1, title: "Port LogBook" },
    { id: 2, title: "IALA Buoyage System" },
    { id: 3, title: "Draft Survey Calculator" },
    { id: 4, title: "Fuel Oil Calculator" },
    { id: 5, title: "ME Power Calculator" },
    { id: 6, title: "Stowage Plan Calculator" },
    { id: 7, title: "Ballast Calculator" },
    { id: 8, title: "Cargo Trimming Calculator" },
    { id: 9, title: "ETA Calculator" },
    { id: 10, title: "Slip Calculator" },
    { id: 11, title: "Unit Converter" },
    { id: 12, title: "Humidity Calculator" },
    { id: 13, title: "List Calculator" },
    { id: 14, title: "Interpolation Calculator" },
    { id: 15, title: "Squat & UKC calculator" },
    { id: 16, title: "Change Draft & GM Calculator" },
    { id: 17, title: "Country Flags" },
    { id: 18, title: "Video Courses and Theory for any crew member" },
  ],
  ua: [
    { id: -3, title: "Marine crewing", new: true },
    { id: -2, title: "Margram", new: true },
    { id: -1, title: "Marine News", new: true },
    { id: 1, title: "Port LogBook" },
    { id: 2, title: "IALA Buoyage System" },
    { id: 3, title: "Draft Survey Calculator" },
    { id: 4, title: "Fuel Oil Calculator" },
    { id: 5, title: "ME Power Calculator" },
    { id: 6, title: "Stowage Plan Calculator" },
    { id: 7, title: "Ballast Calculator" },
    { id: 8, title: "Cargo Trimming Calculator" },
    { id: 9, title: "ETA Calculator" },
    { id: 10, title: "Slip Calculator" },
    { id: 11, title: "Unit Converter" },
    { id: 12, title: "Humidity Calculator" },
    { id: 13, title: "List Calculator" },
    { id: 14, title: "Interpolation Calculator" },
    { id: 15, title: "Squat & UKC calculator" },
    { id: 16, title: "Change Draft & GM Calculator" },
    { id: 17, title: "Country Flags" },
    { id: 18, title: "Video Courses and Theory for any crew member" },
  ],
};

const getTitleById = (id, lang) => {
  const product = this.products[lang].find((item) => item.id === id);
  return product ? product.title : "";
};

exports.ModalItems = {
  en: [
    {
      id: -3,
      url: "/images/modal/crewing.png",
      title: getTitleById(-3, "en"),
      subTitle: " Your Gateway to Seafaring Opportunities",
      text1bold: "For Seafarers:",
      ul1: [
        "Convenience and mobility: \n Ability to apply for vacancies directly from your phone at any time and from anywhere.",
        "Quick communication with the employer: \n Sending the Application Form directly to the company’s email speeds up the application review process.",
        "Updated information about vacancies: \n Seafarers are always up to date  current vacancies, which increases their chances of employment.",
        "Easy to apply: \n A simple and intuitive interface allows you to quickly fill out and submit your application.",
        "Organized data: \n All necessary documents and information can be collected in one place, making the application process easier.",
      ],
      text3bold: "For Crewing Companies:",
      ul2: [
        "Access to a large number of candidates: \n Possibility of posting vacancies and receiving responses from seafarers from all over the world.",
        "Recruiting efficiency: \n The system simplifies the recruitment process, saving time on searching and selecting candidates.",
        "Direct contact with candidates: \n Applications are received directly by email , which speeds up the process of processing and communication with candidates.",
        "Increasing brand awareness: \n The presence of a company in the iur APP increases its visibility and recognition among seafarers.",
        "Simplifying vacancy management: \n The ability to quickly update and manage vacancies in one space.",
      ],
      text5bold: "Benefits for All:",
      ul3: [
        " Digitalization of the process: \n The application facilitates the transition to digital methods of recruiting, which increases overall efficiency.",
        "Reduced time costs: \n Automation of application submission and processing saves time for all participants in the process. ",
        "Transparency and reliability: \n A centralized system with up-to-date information contributes to the transparency and reliability of the recruitment process.",
        "Feedback:  \n The ability to quickly receive feedback and questions from seafarers and companies, which improves communication and quality of service.",
        "Global Reach: \n The application allows crewing companies and seafarers to interact internationally, thereby expanding employment and recruitment opportunities.",
        "Integration of crewing into the Margram service : \n A social network for sailors in the Marine Survey app",
      ],
    },
    {
      id: -2,
      url: "/images/margram/3/2.png",
      title: getTitleById(-2, "en"),
      subTitle: "New opportunities",
      ul1: [],
      text3:
        "Sail into a world of new opportunities with MARGRAM: here every sailor is part of a big family!",
    },
    {
      id: -1,
      url: "/images/modal/19.png",
      title: getTitleById(-1, "en"),
      subTitle: " The Latest Maritime Updates",
      text1:
        "This new feature brings you the freshest and most up-to-date news from the world of marine and maritime affairs. Stay informed on industry developments, port updates, vessel movements, and everything that matters in the nautical realm. ",
      text3bold:
        "Now you'll never miss a beat, as we bring the latest maritime updates straight to your fingertips.",
    },
    {
      id: 1,
      url: "/images/modal/1.png",
      title: getTitleById(1, "en"),
      subTitle: "For any crewmember",
      text1:
        "Create dates, for example, the ship stays in port 5 days, which means all records for these days will be in a safe place at your hands.",
      text2:
        " Entries for vessel arrival and departure. You can easily and quickly add any entries for arrival and departure such as:",
      ul1: ["NOR", "EOSP", "Anchor dropped", "Anchor clear", "POB"],
      text3: "...and other necessary entries. As well as manual input data.",
      text3bold:
        "All necessary records will always be saved in the created port and always at hands.",
      text4:
        "Here you can create any notes (time and text) you want. Data is stored in the 'Section Date:'",
      ul2: ["Commenced discharging", "PSC on board", "Rain started"],
      text5: "...and any records you want.",
      ul4: null,
      text6:
        "Thus, all recordings during the day will be saved in the APP and always at hand.",
      ul5: null,
    },
    {
      id: 2,
      url: "/images/modal/2.png",
      title: getTitleById(2, "en"),
      subTitle: "For Navigators",
      text1: "Complete information about regions A and B:",
      text2: null,
      ul1: [
        "Lateral Marks 'A'",
        "Lateral Marks 'B'",
        "Cardinal marks",
        "Isolated danger marks",
        "Safe water marks",
        "Special marks",
        "Emergency Wreck Buoys",
      ],
      text3: null,
      text3bold: null,
    },
    {
      id: 3,
      url: "/images/modal/3.png",
      title: getTitleById(3, "en"),
      subTitle: "Manual and automatic calculation of loaded / unloaded cargo.",
      text1:
        "The main purpose of Draft Survey - determination of the amount of cargo loaded or unloaded according to the ship's marks (drafts).",
      text2: "Draft Survey procedure consists of two stages:",
      ul1: ["before - Initial ", "and after - Final"],
      text3:
        "In our calculator you can easily calculate it and download .pdf report.",
    },
    {
      id: 4,
      url: "/images/modal/4.png",
      title: getTitleById(4, "en"),
      subTitle: "For Engineers",
      text1:
        "Easy manual and auto calculation of total Fuel on board tank by tank for Engineers.",
    },
    {
      id: 5,
      url: "/images/modal/5.png",
      title: getTitleById(5, "en"),
      subTitle:
        "Main Engine Power Calculator - Calculation of ME Power for Engineers.",
      text1: null,
    },
    {
      id: 6,
      url: "/images/modal/6.png",
      title: getTitleById(6, "en"),
      subTitle: "For Bridge Officers",
      text1:
        "Calculation of cargo by holds, using SF' of cargo as well as the necessary entries in the port for arrival and departure. ",
    },
    {
      id: 7,
      url: "/images/modal/7.png",
      title: getTitleById(7, "en"),
      subTitle:
        "Easy auto / manual calculation quantity of ballast water on board.",
      text1:
        "With our calculator you can create as many tanks as you want. Simply create a vessel - tank - upload Tank Table in APP and automatically calculate the amount of ballast on board in a matter of seconds. ",
      subTitle2: "For Bridge Officers",
      text4:
        "The Main page in Calculator is showing all ballast tanks and total quantity ballast on board in Metric Tons.",
    },
    {
      id: 8,
      url: "/images/modal/8.png",
      title: getTitleById(8, "en"),
      subTitle: "For Chief Officers",
      text1:
        "Easy Trimming at the end of loading. Calculation quantity of cargo to be loaded in Cargo Holds at the end of loading and get an even keel or requested trim.",
    },
    {
      id: 9,
      url: "/images/modal/9.png",
      title: getTitleById(9, "en"),
      subTitle: null,
      text1:
        "Quick calculation date and time of the ship's arrival, as well as calculation of speed you have to keep to arrive at a given time.",
    },
    {
      id: 10,
      url: "/images/modal/10.png",
      title: getTitleById(10, "en"),
      subTitle: "Calculator for engineers.",
      text1:
        "Sliding of the propeller in water, i.e. the difference between the actual speed of the ship and the one it would have if the propeller didn't rotate in water, but was screwed into a solid body.",
    },
    {
      id: 11,
      url: "/images/modal/11.png",
      title: getTitleById(11, "en"),
      subTitle: "For Navigators",
      text1:
        "Quickly conversion of units such as Stowage Factor, Volume, Length, Speed and Temperature.",
    },
    {
      id: 12,
      url: "/images/modal/12.png",
      title: getTitleById(12, "en"),
      subTitle: "Relative Humidity Calculation",
      text1:
        "Relative humidity is the ratio of the absolute humidity of the air to the density of saturated water vapor at the same temperature, expressed as a percentage.",
      text2:
        "In many cases, it is practically impossible to measure the dew point temperature accurately or at all. In such cases, ventilation requirements can be estimated by comparing the average temperature of the cargo during loading with the outside temperature several times a day.",
      text3bold:
        "In this case, ventilation can be carried out on the following grounds.",
      textItlaic:
        "Ventilate, if the outside dry bulb temperature is less than 3°C lower than the average cargo.",
    },
    {
      id: 13,
      url: "/images/modal/13.png",
      title: getTitleById(13, "en"),
      subTitle: "For Navigators",
      text1: "Calculation of heel in degrees for navigators.",
    },
    {
      id: 14,
      url: "/images/modal/14.png",
      title: getTitleById(14, "en"),
      subTitle: "For any crewmember",
      text3: "Liner and Bilinear Calculator.",
    },
    {
      id: 15,
      url: "/images/modal/15.png",
      title: getTitleById(15, "en"),
      subTitle: "For Navigators",
      text1: "Calculation of Squat and Under Keel Clearance.",
    },
    {
      id: 16,
      url: "/images/modal/16.png",
      title: getTitleById(16, "en"),
      subTitle: "For Navigators",
      text1:
        "Change in ship’s draft and GM when entering water with different density.",
    },
    {
      id: 17,
      url: "/images/modal/17.png",
      title: getTitleById(17, "en"),
      subTitle: "For Navigators",
      text1:
        "Now you will never be mistaken about how to properly hang the flag of any country.",
      text2:
        "Often, we don’t know how to hang the flag correctly and turn to NP 100 for advice.",
      text3:
        "Having a graphic image of all flags on your phone, you will never go wrong.",
    },
    {
      id: 18,
      url: "/images/modal/18.png",
      title: getTitleById(18, "en"),
      subTitle: "Draft Survey / Grain Stability / Program Draft Survey",
      text1: "8 Video Lessons of Draft Survey:",
      ul1: [
        "1. Introductory lesson",
        "2. Vessel dimensions",
        "3. Check drafts",
        "4. Drafts on perpendiculars",
        "5. MMM and Hydrostatic",
        "6. Net Displacement",
        "7. Final Survey",
        "8. Addition",
      ],
      text4: "13 Video Lessons of Grain Stability:",
      ul2: [
        "1. Introductory lesson",
        "2. Preliminary Cargo Play",
        "3. IMO Grain Code, 1991",
        "4. Grain Heeling Moment",
        "5. Hull Strength",
        "6. Change Draft & GM",
        "7. Acceleration criteria",
        "8. Stability Calculation (practice)",
        "9. Intact Stability, Grain Stability (practice)",
        "10. Whether Criteria ",
        "11. Loading Plan",
        "12. Draft Survey",
        "13. NCB Recommendation",
      ],
      text5: "6 Video Lessons of Program Draft Survey:",
      ul3: [
        "1. Introductory lesson",
        "2. Visual Basic",
        "3. Overview of the Draft Survey program",
        "4. Cide Editing",
        "4.1. Editing Code. Practical lesson 1",
        "4.2. Edition Code. Practical lesson 2",
      ],
      textItalic:
        "Full Video Course about Draft Survey Program for any kind of vessels, created sea captain + Excel Program.",
    },
  ],
  ua: [
    {
      id: -3,
      url: "/images/modal/19.png",
      title: getTitleById(-3, "ua"),
      subTitle: "Ваш шлях до можливостей у мореплавстві",
      text1bold: "Для моряків:",
      ul1: [
        "Зручність і мобільність: \n Можливість подати заявку на вакансії прямо з телефону в будь-який час і з будь-якого місця.",
        "Швидка комунікація з роботодавцем: \n Надсилання форми заявки безпосередньо на електронну адресу компанії прискорює процес розгляду заявки. ",
        "Оновлена інформація про вакансії:  \n Моряки завжди в курсі актуальних вакансій, що підвищує їхні шанси на працевлаштування.",
        " Легко застосовувати: \n Простий та інтуїтивно зрозумілий інтерфейс дозволяє швидко заповнити та подати заявку.",
        "Упорядковані дані: \n Усі необхідні документи та інформацію можна зібрати в одному місці, що спрощує процес подання заявки.",
      ],
      text3bold: "Для крюїнгових компаній:",
      ul2: [
        "Доступ до великої кількості кандидатів: \n Можливість розміщення вакансій та отримання відгуків від моряків з усього світу.",
        "Ефективність рекрутингу: \n Система спрощує процес підбору персоналу, економлячи час на пошук і відбір кандидатів. ",
        "Прямий контакт з кандидатами:  \n Заявки надходять безпосередньо електронною поштою, що прискорює процес обробки та спілкування з кандидатами.",
        "Підвищення впізнаваності бренду:\n Присутність компанії в додатку Marine Survey збільшує її впізнаваність і впізнаваність серед моряків.",
        "Спрощення управління вакансіями:  \n Можливість швидкого оновлення та керування вакансіями в одному просторі.",
      ],
      text5bold: "Переваги для всіх:",
      ul3: [
        "Цифровізація процесу: \n Додаток полегшує перехід на цифрові методи рекрутингу, що підвищує загальну ефективність.",
        "Скорочення часових витрат: \n Автоматизація подачі та обробки заявок економить час усіх учасників процесу.",
        "Прозорість і надійність: \n Централізована система з актуальною інформацією сприяє прозорості та надійності процесу підбору персоналу.",
        " Зворотній зв'язок: \n Можливість швидко отримувати відгуки та запитання від моряків і компаній, що покращує комунікацію та якість обслуговування.",
        "Глобальне охоплення: \n Додаток дозволяє крюінговим компаніям і морякам взаємодіяти на міжнародному рівні, тим самим розширюючи можливості працевлаштування та працевлаштування.",
        " Інтеграція крюїнгу у сервіс Margram: \n Cоціальна мережа для моряків у додаток Marine Survey",
      ],
    },
    {
      id: -2,
      url: "/images/margram/3/2.png",
      title: getTitleById(-2, "ua"),
      subTitle: "Нові можливості",
      ul1: [],
      text3:
        "Відпливайте у світ нових можливостей з MARGRAM: тут кожен моряк є частиною великої родини!",
    },
    {
      id: -1,
      url: "/images/modal/19.png",
      title: getTitleById(-1, "ua"),
      subTitle: "Останні морські новини",
      text1:
        "Ця нова функція приносить вам найсвіжіші та найактуальніші новини зі світу морських та морегосподарських справ. Будьте в курсі розвитку галузі, оновлень портів, руху суден та всього, що має значення у морській сфері.",
      text3bold:
        "Тепер ви ніколи не пропустите важливу інформацію, оскільки ми доставляємо останні морські оновлення прямо у ваші руки.",
    },
    {
      id: 1,
      url: "/images/modal/1.png",
      title: getTitleById(1, "ua"),
      subTitle: "Для будь-якого члена екіпажу",
      text1:
        "Створюйте дати, наприклад, судно стоїть в порту 5 днів, що означає, що всі записи за ці дні будуть в безпечному місці у вас під рукою.",
      text2:
        "Записи про прибуття та відправлення судна. Ви можете легко і швидко додати будь-які записи про прибуття та відправлення, такі як:",
      ul1: ["NOR", "EOSP", "Якір кинуто", "Якір піднято", "Лоцман на борту"],
      text3: "...та інші необхідні записи. А також ручне введення даних.",
      text3bold:
        "Всі необхідні записи завжди будуть збережені в створеному порту і завжди під рукою.",
      text4:
        "Тут ви можете створити будь-які нотатки (час і текст), які забажаєте. Дані зберігаються в 'Розділі Дата:'",
      ul2: ["Почато розвантаження", "PSC на борту", "Почався дощ"],
      text5: "...і будь-які записи, які ви хочете.",
      ul4: null,
      text6:
        "Таким чином, всі записи протягом дня будуть збережені в додатку і завжди під рукою.",
      ul5: null,
    },
    {
      id: 2,
      url: "/images/modal/2.png",
      title: getTitleById(2, "ua"),
      subTitle: "Для штурманів",
      text1: "Повна інформація про регіони A та B:",
      text2: null,
      ul1: [
        "Латеральні знаки 'A'",
        "Латеральні знаки 'B'",
        "Кардинальні знаки",
        "Знаки ізольованої небезпеки",
        "Знаки безпечних вод",
        "Спеціальні знаки",
        "Аварійні буї на місці затонулого судна",
      ],
      text3: null,
      text3bold: null,
    },
    {
      id: 3,
      url: "/images/modal/3.png",
      title: getTitleById(3, "ua"),
      subTitle:
        "Ручний та автоматичний розрахунок завантаженого / розвантаженого вантажу.",
      text1:
        "Основна мета Драфт-сюрвею - визначення кількості завантаженого або розвантаженого вантажу за марками судна (осадками).",
      text2: "Процедура Драфт-сюрвею складається з двох етапів:",
      ul1: ["до - Початковий", "та після - Фінальний"],
      text3:
        "У нашому калькуляторі ви можете легко розрахувати це та завантажити звіт у форматі .pdf.",
    },
    {
      id: 4,
      url: "/images/modal/4.png",
      title: getTitleById(4, "ua"),
      subTitle: "Для механіків",
      text1:
        "Легкий ручний та автоматичний розрахунок загальної кількості палива на борту по танкам для механіків.",
    },
    {
      id: 5,
      url: "/images/modal/5.png",
      title: getTitleById(5, "ua"),
      subTitle:
        "Калькулятор потужності головного двигуна - Розрахунок потужності ГД для механіків.",
      text1: null,
    },
    {
      id: 6,
      url: "/images/modal/6.png",
      title: getTitleById(6, "ua"),
      subTitle: "Для палубних офіцерів",
      text1:
        "Розрахунок вантажу по трюмах, використовуючи питомий погрузний об'єм вантажу, а також необхідні записи в порту для прибуття та відправлення.",
    },
    {
      id: 7,
      url: "/images/modal/7.png",
      title: getTitleById(7, "ua"),
      subTitle:
        "Легкий автоматичний / ручний розрахунок кількості баластної води на борту.",
      text1:
        "За допомогою нашого калькулятора ви можете створити стільки танків, скільки захочете. Просто створіть судно - танк - завантажте Таблицю Танків в додаток і автоматично розрахуйте кількість баласту на борту за лічені секунди.",
      subTitle2: "Для палубних офіцерів",
      text4:
        "Головна сторінка Калькулятора показує всі баластні танки та загальну кількість баласту на борту в метричних тоннах.",
    },
    {
      id: 8,
      url: "/images/modal/8.png",
      title: getTitleById(8, "ua"),
      subTitle: "Для старших помічників",
      text1:
        "Легке тримування в кінці завантаження. Розрахунок кількості вантажу, що має бути завантажений у вантажні трюми в кінці завантаження, для отримання рівного кіля або необхідного диференту.",
    },
    {
      id: 9,
      url: "/images/modal/9.png",
      title: getTitleById(9, "ua"),
      subTitle: null,
      text1:
        "Швидкий розрахунок дати та часу прибуття судна, а також розрахунок швидкості, яку потрібно підтримувати, щоб прибути у заданий час.",
    },
    {
      id: 10,
      url: "/images/modal/10.png",
      title: getTitleById(10, "ua"),
      subTitle: "Калькулятор для механіків.",
      text1:
        "Ковзання гвинта у воді, тобто різниця між фактичною швидкістю судна і тією, яку б воно мало, якби гвинт не обертався у воді, а вкручувався в тверде тіло.",
    },
    {
      id: 11,
      url: "/images/modal/11.png",
      title: getTitleById(11, "ua"),
      subTitle: "Для штурманів",
      text1:
        "Швидке перетворення одиниць вимірювання, таких як питомий навантажувальний об'єм, об'єм, довжина, швидкість та температура.",
    },
    {
      id: 12,
      url: "/images/modal/12.png",
      title: getTitleById(12, "ua"),
      subTitle: "Розрахунок відносної вологості",
      text1:
        "Відносна вологість - це відношення абсолютної вологості повітря до густини насиченої водяної пари при тій же температурі, виражене у відсотках.",
      text2:
        "У багатьох випадках практично неможливо точно виміряти температуру точки роси або взагалі її виміряти. У таких випадках вимоги до вентиляції можна оцінити, порівнюючи середню температуру вантажу під час завантаження із зовнішньою температурою кілька разів на день.",
      text3bold:
        "У цьому випадку вентиляцію можна проводити на таких підставах.",
      textItlaic:
        "Вентилюйте, якщо зовнішня температура сухого термометра менше ніж на 3°C нижча за середню температуру вантажу.",
    },
    {
      id: 13,
      url: "/images/modal/13.png",
      title: getTitleById(13, "ua"),
      subTitle: "Для штурманів",
      text1: "Розрахунок крену в градусах для штурманів.",
    },
    {
      id: 14,
      url: "/images/modal/14.png",
      title: getTitleById(14, "ua"),
      subTitle: "Для будь-якого члена екіпажу",
      text3: "Лінійний та білінійний калькулятор.",
    },
    {
      id: 15,
      url: "/images/modal/15.png",
      title: getTitleById(15, "ua"),
      subTitle: "Для штурманів",
      text1: "Розрахунок просідання судна та запасу води під кілем.",
    },
    {
      id: 16,
      url: "/images/modal/16.png",
      title: getTitleById(16, "ua"),
      subTitle: "Для штурманів",
      text1:
        "Зміна осадки судна та метацентричної висоти при входженні у воду з іншою густиною.",
    },
    {
      id: 17,
      url: "/images/modal/17.png",
      title: getTitleById(17, "ua"),
      subTitle: "Для штурманів",
      text1:
        "Тепер ви ніколи не помилитесь у тому, як правильно вивісити прапор будь-якої країни.",
      text2:
        "Часто ми не знаємо, як правильно вивісити прапор і звертаємось за порадою до NP 100.",
      text3:
        "Маючи графічне зображення всіх прапорів на вашому телефоні, ви ніколи не помилитесь.",
    },
    {
      id: 18,
      url: "/images/modal/18.png",
      title: getTitleById(18, "ua"),
      subTitle: "Драфт-сюрвей / Зернова остійність / Програма Драфт-сюрвею",
      text1: "8 відеоуроків з Драфт-сюрвею:",
      ul1: [
        "1. Вступний урок",
        "2. Розміри судна",
        "3. Перевірка осадок",
        "4. Осадки на перпендикулярах",
        "5. MMM та гідростатика",
        "6. Чисте водотоннажність",
        "7. Фінальний сюрвей",
        "8. Додаток",
      ],
      text4: "13 відеоуроків з Зернової остійності:",
      ul2: [
        "1. Вступний урок",
        "2. Попередній розрахунок вантажу",
        "3. Кодекс IMO по зерну, 1991",
        "4. Кренувальний момент зерна",
        "5. Міцність корпусу",
        "6. Зміна осадки та метацентричної висоти",
        "7. Критерії прискорення",
        "8. Розрахунок остійності (практика)",
        "9. Остійність неушкодженого судна, Зернова остійність (практика)",
        "10. Критерії погоди",
        "11. План завантаження",
        "12. Драфт-сюрвей",
        "13. Рекомендації NCB",
      ],
      text5: "6 відеоуроків з Програми Драфт-сюрвею:",
      ul3: [
        "1. Вступний урок",
        "2. Visual Basic",
        "3. Огляд програми Драфт-сюрвею",
        "4. Редагування коду",
        "4.1. Редагування коду. Практичний урок 1",
        "4.2. Редагування коду. Практичний урок 2",
      ],
      textItalic:
        "Повний відеокурс про програму Драфт-сюрвею для будь-яких типів суден, створений капітаном + Excel програма.",
    },
  ],
};

exports.margram = {
  slogan: "Social network for Maritime Crews and Companies",
  slogan1: {
    en: "Social network for Maritime Crews and Companies",
    ua: "Соціальна мережа для морських екіпажів та компаній",
  },
  callToAction: {
    en: "Click to Explore Margram!",
    ua: "Натисніть, щоб дізнатись більше про Margram!",
  },
  slogan2: {
    en: "Dive into a new wave of networking!",
    ua: "Пориньте у нову хвилю нетворкінгу!",
  },
};

exports.forSeafarers = {
  en: {
    1: {
      a: "1. Convenience and mobility:",
      b: "Ability to apply for vacancies directly from your phone at any time and from anywhere.",
    },
    2: {
      a: "2. Quick communication with the employer: ",
      b: "Sending the Application Form directly to the company’s email speeds up the application review process. ",
    },
    3: {
      a: "3. Updated information about vacancies: ",
      b: "Seafarers are always up to date  current vacancies, which increases their chances of employment.",
    },
    4: {
      a: "4. Easy to apply:",
      b: "A simple and intuitive interface allows you to quickly fill out and submit your application.",
    },
    5: {
      a: "5. Organized data:",
      b: "All necessary documents and information can be collected in one place, making the application process easier.",
    },
  },
  ua: {
    1: {
      a: " 1. Зручність і мобільність:",
      b: "Можливість подати заявку на вакансії прямо з телефону в будь-який час і з будь-якого місця. ",
    },
    2: {
      a: "2. Швидка комунікація з роботодавцем: ",
      b: "Надсилання форми заявки безпосередньо на електронну адресу компанії прискорює процес розгляду заявки.  ",
    },
    3: {
      a: "3. Оновлена інформація про вакансії:  ",
      b: "Моряки завжди в курсі актуальних вакансій, що підвищує їхні шанси на працевлаштування.",
    },
    4: {
      a: "4. Легко застосовувати: ",
      b: "Простий та інтуїтивно зрозумілий інтерфейс дозволяє швидко заповнити та подати заявку.",
    },
    5: {
      a: "5. Упорядковані дані:",
      b: "Усі необхідні документи та інформацію можна зібрати в одному місці, що спрощує процес подання заявки.",
    },
  },
};

exports.forCrewing = {
  en: {
    1: {
      a: "1. Access to a large number of candidates: ",
      b: "Possibility of posting vacancies and receiving responses from seafarers from all over the world. ",
    },
    2: {
      a: "2. Recruiting efficiency: ",
      b: "The system simplifies the recruitment process, saving time on searching and selecting candidates. ",
    },
    3: {
      a: "3. Direct contact with candidates:  ",
      b: "Applications are received directly by email , which speeds up the process of processing and communication with candidates. ",
    },
    4: {
      a: "4. Increasing brand awareness: ",
      b: "The presence of a company in the iur APP increases its visibility and recognition among seafarers. ",
    },
    5: {
      a: "5. Simplifying vacancy management: ",
      b: "The ability to quickly update and manage vacancies in one space.",
    },
  },
  ua: {
    1: {
      a: " 1. Доступ до великої кількості кандидатів:",
      b: "Можливість розміщення вакансій та отримання відгуків від моряків з усього світу.  ",
    },
    2: {
      a: "2. Ефективність рекрутингу: ",
      b: "Система спрощує процес підбору персоналу, економлячи час на пошук і відбір кандидатів.  ",
    },
    3: {
      a: "3. Прямий контакт з кандидатами:  ",
      b: "Заявки надходять безпосередньо електронною поштою, що прискорює процес обробки та спілкування з кандидатами. ",
    },
    4: {
      a: "4. Підвищення впізнаваності бренду: ",
      b: "Присутність компанії в додатку Marine Survey збільшує її впізнаваність і впізнаваність серед моряків.",
    },
    5: {
      a: "5. Спрощення управління вакансіями: ",
      b: "Можливість швидкого оновлення та керування вакансіями в одному просторі.",
    },
  },
};

exports.forAll = {
  en: {
    1: {
      a: "1. Digitalization of the process: ",
      b: "The application facilitates the transition to digital methods of recruiting, which increases overall efficiency. ",
    },
    2: {
      a: "2. Reduced time costs: ",
      b: "Automation of application submission and processing saves time for all participants in the process.  ",
    },
    3: {
      a: "3. Transparency and reliability:  ",
      b: "A centralized system with up-to-date information contributes to the transparency and reliability of the recruitment process. ",
    },
    4: {
      a: "4. Feedback:  ",
      b: "The ability to quickly receive feedback and questions from seafarers and companies, which improves communication and quality of service. ",
    },
    5: {
      a: "5. Global Reach: ",
      b: "The application allows crewing companies and seafarers to interact internationally, thereby expanding employment and recruitment opportunities.",
    },
    6: {
      a: "Integration of crewing into the Margram service :",
      b: "A social network for sailors in the Marine Survey app",
    },
  },
  ua: {
    1: {
      a: "1. Цифровізація процесу: ",
      b: "Додаток полегшує перехід на цифрові методи рекрутингу, що підвищує загальну ефективність.  ",
    },
    2: {
      a: "2. Скорочення часових витрат: ",
      b: "Автоматизація подачі та обробки заявок економить час усіх учасників процесу.",
    },
    3: {
      a: "3. Прозорість і надійність:  ",
      b: "Централізована система з актуальною інформацією сприяє прозорості та надійності процесу підбору персоналу.",
    },
    4: {
      a: "4. Зворотній зв'язок:  ",
      b: "Можливість швидко отримувати відгуки та запитання від моряків і компаній, що покращує комунікацію та якість обслуговування.",
    },
    5: {
      a: "5. Глобальне охоплення: ",
      b: "Додаток дозволяє крюінговим компаніям і морякам взаємодіяти на міжнародному рівні, тим самим розширюючи можливості працевлаштування та працевлаштування.",
    },
    6: {
      a: "6. Інтеграція крюїнгу у сервіс Margram:  ",
      b: "Cоціальна мережа для моряків у додаток Marine Survey",
    },
  },
};
