/* eslint-disable react/no-array-index-key */
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "../../components/Typography";

export default function InstructionCard({ items }) {
  return items.map((item, index) => {
    return (
      <Grid
        item
        sm={4}
        sx={{ ml: { sm: 2 }, mr: { sm: 1, md: 7 } }}
        key={index}
      >
        <Card
          sx={{
            width: "180px",
            minHeight: "350px",
            mx: 1,
            mb: 1,
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        >
          {/* <CardActionArea> */}
          <CardMedia
            component="img"
            height={185}
            image={item.img}
            alt={item.text1}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item.text1}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.text2}
            </Typography>
          </CardContent>
          {/* </CardActionArea> */}
        </Card>
      </Grid>
    );
  });
}
