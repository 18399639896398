/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from "react";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import Icon from "../../components/Icon";
import { forAll } from "../../../CONTACTS";

function ListThree() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const parentControls = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  const childControls = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const controlsRight = {
    hidden: { opacity: 0, x: 1100 },
    visible: { opacity: 1, x: 110 },
  };

  const selectedLangData = forAll[lang];

  const listRef = useRef(null);
  const [listInView, setListInView] = useState(false);
  const listAnimation = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const listTop = listRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (listTop <= windowHeight) {
          setListInView(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (listInView) {
      listAnimation.start("visible");
    }
  }, [listInView, listAnimation]);

  return (
    <Container>
      <Typography
        variant="h3"
        sx={{ my: { xs: 5, md: 10 }, textAlign: "center" }}
      >
        {lang === "en" ? "Benefits for All:" : "Переваги для всіх:"}
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          my: 0,
        }}
      >
        <motion.div
          ref={listRef}
          initial="hidden"
          animate={listAnimation}
          variants={parentControls}
        >
          {Object.values(selectedLangData).map((el, index) => (
            <motion.div
              key={index}
              variants={childControls}
              transition={{ duration: 1.5 }}
            >
              <Typography
                variant="h6"
                align="left"
                sx={{
                  p: 1,
                  color: "white",
                  transition: "transform 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Icon src="/icons/arrow4.png" sx={{ mr: 3 }} />
                {el.a}
              </Typography>
              <Typography
                sx={{
                  p: 1,
                  transition: "transform 0.3s ease",
                  display: "flex",
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                {el.b}
              </Typography>
            </motion.div>
          ))}
        </motion.div>
      </Grid>
    </Container>
  );
}

export default ListThree;
