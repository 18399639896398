import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Fab } from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Zoom from "@mui/material/Zoom";

const ScrollTop = styled(Fab)(({ theme, isvisible }) => ({
  position: "fixed",
  bottom: theme.spacing(1),
  left: theme.spacing(1),
  color: theme.palette.primary.dark,
}));

export default function ScrollButton({ onClick }) {
  const handleScroll = () => {
    if (onClick) {
      // If an onClick callback is provided, call it
      onClick();
    } else {
      // If no onClick callback is provided, scroll to the top
      scroll.scrollToTop({
        smooth: true,
      });
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScrolling = () => {
      // Hide button if at top
      if (window.pageYOffset === 0) {
        setIsVisible(false);
        return;
      }

      setIsVisible(true);
    };

    window.addEventListener("scroll", handleScrolling);

    return () => window.removeEventListener("scroll", handleScrolling);
  }, []);

  return (
    isVisible && (
      <Zoom in>
        <ScrollTop
          onClick={handleScroll}
          color="secondary"
          size="large"
          aria-label="scroll button"
        >
          <KeyboardArrowUpIcon />
        </ScrollTop>
      </Zoom>
    )
  );
}
