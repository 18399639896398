import iosFree from "./iosfree";
import iosPro from "./iospro";
import androidPro from "./androidpro";
import androidFree from "./androidfree";

const getPolicy = (policyObj, lang) => {
  console.log("policyObj", policyObj);

  if (policyObj[lang]) {
    console.log(policyObj[lang]);
    return policyObj[lang];
  }
  console.error(`Language ${lang} not found in policy`);
  return "Policy not available in selected language";
};

const vPolicies = ({ lang }) => ({
  iosPro: {
    title: "IOS PRO",
    policy: getPolicy(iosPro.iosPro, lang),
  },
  iosFree: {
    title: "IOS FREE",
    policy: getPolicy(iosFree.iosFree, lang),
  },
  androidPro: {
    title: "Android PRO",
    policy: getPolicy(androidPro.androidPro, lang),
  },
  androidFree: {
    title: "Android FREE",
    policy: getPolicy(androidFree.androidFree, lang),
  },
});

export default vPolicies;
