/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState, useMemo } from "react";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import Icon from "../../components/Icon";
import { forCrewing } from "../../../CONTACTS";

function ListTwo({ isSmallScreen }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const parentControls = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  const childControls = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };
  const xAxis = useMemo(() => (isSmallScreen ? 0 : -220), [isSmallScreen]);
  const controlsLeft = {
    hidden: { opacity: 0, x: -1100 },
    visible: { opacity: 1, x: xAxis },
  };

  const selectedLangData = useMemo(() => forCrewing[lang], [lang]);

  const imageRef = useRef(null);
  const listRef = useRef(null);
  const [imageInView, setImageInView] = useState(false);
  const [listInView, setListInView] = useState(false);
  const imageAnimation = useAnimation();
  const listAnimation = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (imageRef.current) {
        const imageTop = imageRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (imageTop <= windowHeight) {
          setImageInView(true);
        }
      }
      if (listRef.current) {
        const listTop = listRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (listTop <= windowHeight) {
          setListInView(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (imageInView) {
      imageAnimation.start("visible");
    }
    if (listInView) {
      listAnimation.start("visible");
    }
  }, [imageInView, listInView, imageAnimation, listAnimation]);

  // Memoize the list items to avoid recalculating on each render
  const memoizedListItems = useMemo(
    () =>
      Object.values(selectedLangData).map((el, index) => (
        <motion.div
          key={index}
          variants={childControls}
          transition={{ duration: 1.5 }}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              p: 1,
              color: "white",
              transition: "transform 0.3s ease",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <Icon src="/icons/arrow4.png" sx={{ mr: 3 }} />
            {el.a}
          </Typography>
          <Typography
            sx={{
              p: 1,
              transition: "transform 0.3s ease",
              display: "flex",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            {el.b}
          </Typography>
        </motion.div>
      )),
    [selectedLangData, childControls],
  );
  return (
    <Container>
      <Typography
        variant="h3"
        sx={{ my: { xs: 5, md: 10 }, textAlign: "center" }}
      >
        {lang === "en"
          ? "For crewing companies: "
          : "Для крюїнгових компаній: "}
      </Typography>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6} sx={{ textAlign: "center", my: 0 }}>
          <motion.div
            ref={imageRef}
            initial="hidden"
            animate={imageAnimation}
            variants={controlsLeft}
            transition={{ duration: 1.5 }}
          >
            <img
              src="/images/crewing/2.png"
              alt="image_crewing"
              style={{ width: isSmallScreen ? "100%" : "135%" }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "center", my: 0 }}>
          <motion.div
            ref={listRef}
            initial="hidden"
            animate={listAnimation}
            variants={parentControls}
          >
            {memoizedListItems}
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ListTwo;
