/* eslint-disable react/no-array-index-key */

/* eslint-disable react/button-has-type */

import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Container, Grid, Box } from "@mui/material";
import { useKeenSlider } from "keen-slider/react";
import { useTranslation } from "react-i18next";
import TitleWithCircles from "../../components/TitleWithCircles";
import "keen-slider/keen-slider.min.css";
import Typography from "../../components/Typography";

const Dots = styled("div")({
  display: "flex",
  padding: "10px 0",
  justifyContent: "center",
});

const Dot = styled("button")(({ theme, active }) => ({
  border: "none",
  width: "10px",
  height: "10px",
  background: active ? "#000" : theme.palette.secondary.main,
  borderRadius: "50%",
  margin: "0 5px",
  padding: "5px",
  cursor: "pointer",
  outline: "none",
  "&:focus": {
    outline: "none",
  },
}));

const StyledArrow = styled("div")(({ left, disabled, theme }) => ({
  width: "30px",
  height: "30px",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  color: disabled ? theme.palette.secondary.light : "#000",
  onHover: "theme.palette.secondary.light",
  cursor: "pointer",
  left: left ? "5px" : "auto",
  right: !left ? "5px" : "auto",
}));

const screenshots = [
  "/images/margram/3/1.png",
  "/images/margram/3/2.png",
  "/images/margram/3/3.png",
  "/images/margram/3/4.png",
  "/images/margram/3/5.png",
  "/images/margram/3/6.png",
];

function Screenshots() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free",
    loop: true,
    origin: "center",
    breakpoints: {
      "(min-width: 1000px)": {
        slides: { perView: 6 },
      },
      "(min-width: 740px)": {
        slides: { perView: 4 },
      },
    },
    slides: { perView: 1, spacing: 15 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <section>
      <Container
        sx={{
          my: 5,
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <Container> */}
        {/* <Grid
          container
          sx={{
            mt: 5,
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            display: "flex",
          }}
        > */}
        {/* <Grid item md={12}> */}
        <Typography variant="h3" sx={{ mb: 5, textAlign: "center" }}>
          {lang === "en" ? "Screenshots" : "Скріншоти"}
        </Typography>
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Container> */}
        <Box
          ref={sliderRef}
          className="keen-slider"
          sx={{ gap: "0px", overflow: "hidden" }}
        >
          {screenshots.map((screenshot, index) => (
            <Box
              key={index}
              className="keen-slider__slide"
              sx={{
                minWidth: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={screenshot}
                alt={`Screenshot ${index + 1}`}
                style={{ width: "225px", height: "auto" }}
              />
            </Box>
          ))}
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </Box>
        {loaded && instanceRef.current && (
          <Dots>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => (
              <Dot
                key={idx}
                onClick={() => instanceRef.current?.moveToIdx(idx)}
                active={currentSlide === idx}
              />
            ))}
          </Dots>
        )}
      </Container>
    </section>
  );
}

export default Screenshots;

function Arrow({ disabled, onClick, left }) {
  return (
    <StyledArrow onClick={onClick} disabled={disabled} left={left}>
      {left ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      )}
    </StyledArrow>
  );
}
