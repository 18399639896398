/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, Box } from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import Typography from "../../components/Typography";

const GradientBorderBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: 2,
  margin: 1,
  // border: "1px solid #ccc",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  // transition: "border 0.3s ease",
  ":hover": {
    border: "2.5px solid",
    borderImageSlice: 1,
    animation: "pulsate-border 3s infinite linear",
  },
  "@keyframes pulsate-border": {
    "0%": {
      borderImageSource: "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
    },
    "50%": {
      borderImageSource: "linear-gradient(60deg, #c21d64, #4a678a, #95ccfc)",
    },
    "100%": {
      borderImageSource: "linear-gradient(60deg, #95ccfc, #c21d64, #4a678a)",
    },
  },
}));
function Benefits() {
  const { t } = useTranslation();

  const benefits = t("partners.benefits", { returnObjects: true });

  const styles = {
    image: {
      width: 65,
      height: 65,
      margin: "10px auto",
    },
  };
  return (
    <Grid container spacing={5} justifyContent="center">
      {benefits.map((benefit, index) => (
        <Grid
          key={index}
          item
          xs={12}
          md={4}
          display="flex"
          justifyContent="center"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
          >
            <GradientBorderBox>
              <Box sx={{ p: 2 }}>
                {/* <Box sx={styles.number}>{index + 1}.</Box> */}
                <Box
                  component="img"
                  src={`/partners/anim-icon${index}.gif`}
                  alt={`/partners/anim-icon${index}.gif`}
                  sx={styles.image}
                />
                <Typography variant="h6" align="center">
                  {benefit.title}
                </Typography>
                <Typography variant="body1" align="center">
                  {benefit.description}
                </Typography>
              </Box>
            </GradientBorderBox>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}

export default Benefits;
