import "../../../styles/app-light.css";
import "../../../styles/light.css";
import React from "react";
import MargramMain from "./MargramMain";
import Two from "./two";
import AppServices from "./AppServices";
import Screens from "./Screens";

function index() {
  return (
    <div>
      <MargramMain />
      <AppServices />
      <Screens />
    </div>
  );
}

export default index;
