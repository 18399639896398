import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material";
import Home from "./Home";
import i18n from "./locales/i18n";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";
import { MyContextProvider } from "./MyContext";

function WithRoot(Component) {
  return function WrappedComponent(props) {
    return (
      <ThemeProvider theme={theme}>
        <MyContextProvider>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <Box
              // fullWidth
              sx={{
                width: "100%",
                minHeight: "100vh",
                bgcolor: "rgba(173, 195, 215, 0.9)",
                zIndex: -11,
              }}
            >
              <Component {...props} />
            </Box>
          </I18nextProvider>
        </MyContextProvider>
      </ThemeProvider>
    );
  };
}

const EnhancedHome = WithRoot(Home);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <EnhancedHome />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
