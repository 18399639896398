/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState, useMemo } from "react";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import Icon from "../../components/Icon";
import { forSeafarers } from "../../../CONTACTS";

function ListOne({ isSmallScreen }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const parentControls = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  const childControls = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const controlsRight = {
    hidden: { opacity: 0, x: 2000 },
    visible: { opacity: 1, x: 0 },
  };

  // Memoize the selected language data
  const selectedLangData = useMemo(() => forSeafarers[lang], [lang]);

  const listRef = useRef(null);
  const [listInView, setListInView] = useState(false);
  const listAnimation = useAnimation();
  const imageRef = useRef(null);
  const [imageInView, setImageInView] = useState(false);
  const imageAnimation = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const listTop = listRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (listTop <= windowHeight) {
          setListInView(true);
        }
      }
      if (imageRef.current) {
        const imageTop = imageRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (imageTop <= windowHeight) {
          setImageInView(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (listInView) {
      listAnimation.start("visible");
    }
    if (imageInView) {
      imageAnimation.start("visible");
    }
  }, [listInView, listAnimation, imageInView, imageAnimation]);

  // Memoize the list items to avoid recalculating on each render
  const memoizedListItems = useMemo(
    () =>
      Object.values(selectedLangData).map((el, index) => (
        <motion.div
          key={index}
          variants={childControls}
          transition={{ duration: 1.5 }}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              p: 1,
              color: "white",
              transition: "transform 0.3s ease",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <Icon src="/icons/arrow4.png" sx={{ mr: 3 }} />
            {el.a}
          </Typography>
          <Typography
            sx={{
              p: 1,
              transition: "transform 0.3s ease",
              display: "flex",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            {el.b}
          </Typography>
        </motion.div>
      )),
    [selectedLangData, childControls],
  );

  return (
    <Container>
      <Typography
        variant="h3"
        sx={{ my: { xs: 5, md: 10 }, textAlign: "center" }}
      >
        {lang === "en" ? "For seafarers: " : "Для моряків: "}
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid item xs={12} md={6} sx={{ textAlign: "center", my: 0 }}>
          <motion.div
            ref={listRef}
            initial="hidden"
            animate={listAnimation}
            variants={parentControls}
          >
            {memoizedListItems}
          </motion.div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <motion.div
            ref={imageRef}
            variants={controlsRight}
            initial="hidden"
            animate={imageAnimation}
            transition={{ duration: 1.5 }}
          >
            <img
              src="/images/crewing/1a.png"
              alt="image_crewing"
              style={{ width: isSmallScreen ? "100%" : "135%" }}
            />
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ListOne;
