import { useState, useEffect } from "react";

const useLoading = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const asyncOperation = async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });
      setLoading(false);
    };

    asyncOperation();
  }, []);

  return loading;
};

export default useLoading;
