import React, { useState, useEffect } from "react";
import { motion, LazyMotion, domAnimation, m } from "framer-motion";
import { styled } from "@mui/system";
import { Stack, Link } from "@mui/material/";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { CONTACTS } from "../../../CONTACTS";

const backgroundImage = "/images/ship113.webp";
const apple = "/icons/app3.png";
const google = "/icons/google.png";

const StyledStack = styled(Stack)({
  direction: { xs: "column", sm: "column", md: "row" },
  spacing: 2,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "40px",
});

const SloganContainer = styled("div")({
  marginBottom: 10,
});

const IconsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

// Animation variants
const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 1,
    ease: "easeInOut",
  },
};

const fadeInTop = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  transition: {
    duration: 2,
    ease: "easeInOut",
  },
};

function ProductHero() {
  const [backgroundPosition, setBackgroundPosition] = useState("0% 100%");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    const delay = setTimeout(() => {
      setBackgroundPosition("50% 50%");
    }, 200);

    return () => clearTimeout(delay);
  }, []);

  return (
    <LazyMotion features={domAnimation}>
      <m.div animate={{ opacity: 1 }}>
        <ProductHeroLayout
          sxBackground={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundColor: "#7fc7d9",
            backgroundPosition,
            transition: "background-position 1s ease-in-out",
          }}
        >
          <StyledStack>
            <SloganContainer>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInTop}
              >
                <Typography
                  color="inherit"
                  marked="center"
                  align="center"
                  variant="h4"
                  zIndex={2}
                  sx={{ mb: 4, mt: { xs: 0, sm: 8 } }}
                  paragraph
                >
                  {CONTACTS.slogan2[lang]}
                </Typography>
              </motion.div>
            </SloganContainer>

            <AnimatedLink
              href={CONTACTS.appleFree}
              src={apple}
              alt="Apple Icon"
            />
            <AnimatedLink
              href={CONTACTS.google}
              src={google}
              alt="Google Icon"
            />
          </StyledStack>
        </ProductHeroLayout>
      </m.div>
    </LazyMotion>
  );
}

const AnimatedLink = React.memo(({ href, src, alt }) => (
  <motion.div initial="hidden" animate="visible" variants={fadeInLeft}>
    <Link href={href} target="_blank">
      <img
        src={src}
        alt={alt}
        style={{
          width: "280px",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      />
    </Link>
  </motion.div>
));

export default React.memo(ProductHero);
