import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

const MyContext = createContext();

export function useMyContext() {
  return useContext(MyContext);
}

export function MyContextProvider({ children }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(() => {
    // Get the saved language from localStorage or default to i18n's initial language
    return localStorage.getItem("language") || i18n.language;
  });

  useEffect(() => {
    // Update localStorage when the language changes
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const contextValue = useMemo(
    () => ({
      lang,
      setLang,
      isMobile,
    }),
    [lang, isMobile],
  );

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
}
