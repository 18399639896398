// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { Box, Select, MenuItem } from "@mui/material";

// function LanguageSwitcher({ sx, handleLanguageSelect, language = "en" }) {
//   const handleChange = (event) => {
//     const selectedLanguage = event.target.value;
//     handleLanguageSelect(selectedLanguage);
//   };

//   return (
//     <Box sx={{ minWidth: 100, ml: 2, p: 0, ...sx }}>
//       <Select
//         value={language}
//         onChange={handleChange}
//         sx={{ color: "#fff", ".MuiSelect-icon": { color: "#fff" }, p: 0 }}
//       >
//         <MenuItem value="en">English</MenuItem>
//         <MenuItem value="ua">Українська</MenuItem>
//       </Select>
//     </Box>
//   );
// }

// export default LanguageSwitcher;

import { useState } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../MyContext";

function LanguageSwitcher({ sx }) {
  const { i18n, t } = useTranslation();
  const { setLang, lang, isMobile } = useMyContext();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    setLang(event.target.value);
    // Add your language change logic here (e.g., update context or global state)
  };

  return (
    <Box sx={{ minWidth: 100, ml: 2, p: 0, ...sx }}>
      <Select
        value={language}
        onChange={handleLanguageChange}
        displayEmpty
        sx={{ color: "#fff", ".MuiSelect-icon": { color: "#fff" }, p: 0 }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ua">Українська</MenuItem>
      </Select>
    </Box>
  );
}

export default LanguageSwitcher;
