/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  CardActions,
  Stack,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import { Tiers as tiers } from "../../../CONTACTS";

function TiersComponent() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const inEqualFree = (str) => {
    return Boolean(str === "Free");
  };
  const onClick = (href) => {
    window.open(href, "_blank");
  };
  return (
    <Grid container spacing={1} alignItems="">
      {tiers[lang].map((tier) => (
        <Grid
          item
          key={tier.title}
          xs={12}
          sm={6}
          md={6}
          pb={tier.isPro ? 0 : 8.55}
          mb={5}
        >
          {" "}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeOut", duration: 2, delay: 2 }}
          >
            <Card
              sx={{
                backgroundColor: "white",
              }}
            >
              <CardHeader
                alignItems="baseline"
                title={tier.title}
                titleTypographyProps={{
                  align: "center",
                  variant: "h4",
                  color: "white",
                }}
                sx={{
                  backgroundColor: tier.isPro
                    ? "secondary.dark"
                    : "primary.dark",
                  p: "2rem",
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h3" variant="h3" color="primary.dark">
                    ${tier.price}
                  </Typography>
                </Box>
                {Object.keys(tier.features).map((featureKey) => (
                  <React.Fragment key={featureKey}>
                    <Typography variant="h6" color="warning.dark">
                      {tier.features[featureKey].title}
                      {!tier.isPro && tier.features[featureKey].price
                        ? `${tier.features[featureKey].price}`
                        : null}
                    </Typography>
                    <ul>
                      {tier.features[featureKey].items.map(
                        (line, itemIndex) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="left"
                            key={itemIndex}
                          >
                            {line.name ? `${line.name}` : `${line}`}
                            {!tier.isPro && line.price ? (
                              <Typography
                                component="span"
                                sx={{
                                  color: inEqualFree(`${line.price}`)
                                    ? "black"
                                    : "warning.main",
                                  ml: "5px",
                                }}
                              >
                                {line.price}
                              </Typography>
                            ) : null}
                          </Typography>
                        ),
                      )}
                    </ul>
                  </React.Fragment>
                ))}
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Stack spacing={1} mb={4}>
                  {tier.buttons.map((button, index) => (
                    <Button
                      key={index}
                      onClick={() => onClick(button.link)}
                      size="large"
                      // color="secondary"
                      // variant="contained"
                      sx={{ mt: 3, p: 2, zIndex: 13 }}
                    >
                      {button.buttonText}
                    </Button>
                  ))}
                </Stack>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}

export default TiersComponent;
