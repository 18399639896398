import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import { CONTACTS } from "../../../CONTACTS";

const styles = {
  container: {
    pt: 15,
    pb: 5,
    mt: -10,
    px: 5,
    alignItems: "center",
    textAlign: "center",
    // bgcolor: "rgba(124, 174, 229, 0.3)",
    overflow: "hidden",
    // width: "100%",
    minHeight: "70vh",
  },
  section: {
    display: "flex",
    bgcolor: "rgba(124, 174, 229, 0.1)",
    overflow: "hidden",
  },
  contentContainer: {
    mt: 5,
    mb: 5,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.7,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    px: 3,
  },
  number: {
    fontSize: 24,
    fontFamily: "default",
    color: "warning.main",
    fontWeight: "medium",
  },
  image: {
    height: 55,
    my: 2,
  },
  button: {
    mt: 4,
    zIndex: 11,
    bgcolor: "primary.main",
  },
};

function About() {
  const { t } = useTranslation();
  const bg = "rgba(124, 174, 229, 0.3)";

  return (
    <>
      <Box sx={styles.container}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          {t("about.title")}
        </Typography>
        <Typography variant="h5" align="left">
          {t("about.text1")}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="warning.main"
          sx={{ my: 1 }}
        >
          {t("about.offline")}
        </Typography>
        <Typography variant="h5" align="left">
          {t("about.text2")}
        </Typography>
      </Box>
      <Box component="section" sx={styles.section}>
        <Container sx={styles.contentContainer}>
          {/* <Box
            component="img"
            src="/icons/CurvyLines.png"
            alt="curvy lines"
            sx={styles.curvyLines}
          /> */}
          <Typography variant="h4" component="h2" sx={{ mb: 6 }}>
            {t("about.how")}
          </Typography>
          <div>
            <Grid container spacing={5}>
              {[1, 2, 3].map((index) => (
                <Grid key={index} item xs={12} md={4}>
                  <Box sx={styles.gridItem}>
                    <Box sx={styles.number}>{index}.</Box>
                    <Box
                      component="img"
                      src={`/icons/free${index}.gif`}
                      alt={`image${index}`}
                      sx={styles.image}
                    />
                    <Typography variant="h6" align="center">
                      {t(`about.${index}`)}
                    </Typography>
                    <Typography variant="body1" align="center">
                      {t(`about.${index}a`)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
          <Button
            size="large"
            href={CONTACTS.googlePro}
            target="_blank"
            sx={styles.button}
          >
            {t("about.button")}
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default About;
