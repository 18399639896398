/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { motion, useAnimation } from "framer-motion";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Grid, Link, List, ListItem, Box } from "@mui/material";
import Typography from "../../components/Typography";
import Icon from "../../components/Icon";
import { CONTACTS } from "../../../CONTACTS";

export default function FeaturedList() {
  const { t } = useTranslation();
  const apple = "/icons/app3.png";
  const google = "/icons/google.png";

  const textDataKeys = ["1", "2", "3", "4", "5"];
  const textData = textDataKeys.map((key) => t(`buy.features.${key}`));
  const controls = {
    hidden: { opacity: 0, x: -61 },
    visible: { opacity: 1, x: 5 },
  };
  const controlsAp = {
    hidden: { y: 100 },
    visible: { y: 0 },
  };

  return (
    <Box sx={{ textAlign: "start" }}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={controls}
        transition={{ duration: 0.5 }}
      >
        <List component={Typography} variant="h6">
          {textData.map((text, index) => (
            <motion.li
              initial="hidden"
              animate="visible"
              variants={controls}
              transition={{ duration: 0.7 }}
              key={index}
            >
              <ListItem>
                <Icon src="./icons/arrow6.png" alt="Arrow Marine Survey" />
                <Typography color="white" mt={0.7}>
                  {text}
                </Typography>
              </ListItem>
            </motion.li>
          ))}
        </List>
        <Typography variant="h6" ml={2.5} color="warning.main">
          {t("buy.downloadProVersion")}
        </Typography>
        <Typography variant="h6" ml={2.5} color="warning.main">
          {t("buy.offline")} {t("buy.noAds")}
        </Typography>
      </motion.div>
      <motion.div
        variants={controlsAp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1.5 }}
        scrollSpyOnce
      >
        <Grid item xs={12} md={6} sx={{ textAlign: "center", my: 3 }}>
          <Link href={CONTACTS.google} target="_blank">
            <img src={google} alt="icons" style={{ width: "280px" }} />
          </Link>
          <Link href={CONTACTS.apple} target="_blank">
            <img src={apple} alt="icons" style={{ width: "280px" }} />
          </Link>
        </Grid>
      </motion.div>
    </Box>
  );
}
