/* eslint-disable react/no-array-index-key */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Grow, Slide } from "@mui/material";
import { useTranslation } from "react-i18next";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Typography from "../../components/Typography";
import { generateImageObjects } from "../../../hooks/funcs";

function Best() {
  const { t } = useTranslation();
  const bestImages = generateImageObjects(5);
  const trigger = useScrollTrigger({
    threshold: 1,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "baseline",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src="/icons/CurvyLines.png"
        alt="curvy lines"
        sx={{
          pointerEvents: "none",
          display: "flex",
          height: "100%",
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          opacity: 0.45,
          zIndex: 1,
          backgroundRepeat: "repeat-y",
        }}
      />
      <Container
        sx={{ mb: 14, mt: 4, alignItems: "center", textAlign: "center" }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h3" marked="center" color="white" zIndex={2}>
              {t("best.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary.dark" zIndex={2}>
              {t("best.text1")}
            </Typography>
            <Typography variant="h5" color="white" zIndex={2}>
              {t("best.text2")}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: "center",
              textAlign: "center",
              direction: "flex",
              justifyContent: "center",
              m: { md: -1 },
              zIndex: 992,
            }}
          >
            {bestImages.map((img, index) => (
              <Grow
                in
                mountOnEnter
                unmountOnExit
                key={index}
                style={{ transitionDelay: `${2000 + 20 * index}ms` }}
              >
                <Grid item xs={12} sm={index < 3 ? 4 : 6} md={2}>
                  <Box
                    component="img"
                    src={img.url}
                    alt={img.alt}
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "220px",
                      mb: { xs: -6 },
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                    }}
                  />
                </Grid>
              </Grow>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Best;
