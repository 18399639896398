/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { motion } from "framer-motion";
import { keyframes } from "@mui/system";
import Typography from "../../components/Typography";

function PartnershipPage() {
  const { t } = useTranslation();

  const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

  const partners = t("partners", { returnObjects: true });

  return (
    <Container maxWidth="lg">
      {/* New Section: Types of Partnership */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2.5, delay: 0.5 }}
      >
        <Typography
          variant="h4"
          sx={{ my: 5, textAlign: "center", color: "warning.main" }}
        >
          {partners.partnershipTitle}
        </Typography>
        <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
          {partners.partnershipOptions}
        </Typography>
      </motion.div>

      <Grid container spacing={5} justifyContent="center">
        {partners.partnershipTypes.map((type, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
            >
              <Card
                sx={{
                  maxWidth: 345,
                  background:
                    "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
                  color: "white",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                    "&::before": {
                      animation: `${shimmer} 2s infinite linear`,
                    },
                  },
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: "-50%",
                    left: "-50%",
                    width: "200%",
                    height: "200%",
                    background:
                      "linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent)",
                    transform: "rotate(30deg)",
                    transition: "all 0.3s ease-in-out",
                  },
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  height="110"
                  image={`/partners/types-${index}.gif`}
                  alt={`image ${index}`}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      mb: 2,
                      zIndex: 999,
                    }}
                  >
                    {type.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {type.description}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PartnershipPage;
