/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import Typography from "../../components/Typography";

function Count({ t }) {
  const items = [
    { number: 15000, text: t("buy.countup.downloads") },
    { number: 500, text: t("buy.countup.purchases") },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* <Box
        sx={{
          position: "sticky",
          zIndex: 2,
          top: 30,
          left: 11,
          right: 127,
          bottom: 0,
          width: "1200hv",
          background: "white",
          //   bgcolor: "primary.main",
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          opacity: 0.3,
        }}
      /> */}
      {items.map((item, index) => (
        <Grid
          item
          xs={12}
          md={6}
          key={index}
          sx={{
            fontSize: 100,
            zIndex: 11,
            alignItems: "center",
            textAlign: "center",
            py: 2,
            my: 2,
          }}
        >
          {" "}
          <motion.div
            initial={{ x: -100, y: -100, opacity: 0 }}
            animate={{ x: 0, y: 0, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 2 }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: isSmallScreen ? 90 : 110,
                color: "warning.main",
              }}
            >
              <CountUp
                delay={0.1}
                //   enableScrollSpy
                scrollSpyOnce
                end={item.number}
                duration={5 + index * 0.3}
                separator=""
                suffix={item.number === 500 ? "+" : ""}
              />
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeOut", duration: 5 }}
          >
            <Typography
              variant={isSmallScreen ? "h4" : "h3"}
              sx={{ m: -2, color: "warning.main" }}
            >
              {item.text}
            </Typography>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}

export default Count;
