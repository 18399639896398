import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const GradientBorderBox = styled(Box)(({ theme }) => ({
  // Ensures content does not touch the animated border
  borderRadius: "10px",
  border: "1.5px solid",
  borderImageSlice: 1,
  animation: "pulsate-border 3s infinite linear",
  "@keyframes pulsate-border": {
    "0%": {
      borderImageSource: "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
    },
    "50%": {
      borderImageSource: "linear-gradient(60deg, #c21d64, #4a678a, #95ccfc)",
    },
    "100%": {
      borderImageSource: "linear-gradient(60deg, #95ccfc, #c21d64, #4a678a)",
    },
  },
  zIndex: 0,
}));

export default GradientBorderBox;

// const GradientBorderBox = styled(Box)(({ theme }) => ({
//   border: "2px solid transparent",
//   borderRadius: theme.shape.borderRadius,
//   background:
//     "linear-gradient(to left, #4a678a, #95ccfc, #c21d64), linear-gradient(white, white)",
//   backgroundClip: "padding-box, border-box",
//   backgroundOrigin: "border-box",
//   animation: "pulsate 3s infinite",
//   "@keyframes pulsate": {
//     "0%": {
//       opacity: 1,
//     },
//     "50%": {
//       opacity: 0.5,
//     },
//     "100%": {
//       opacity: 1,
//     },
//   },
// }));
