import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import InstructionCard from "./InstructionCard";

const image = {
  height: 550,
  my: 1,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
};

function Instruction() {
  const { t } = useTranslation();
  const cards = [
    {
      img: "/images/card1.png",
      text1: t("instruction.text1-1"),
      text2: t("instruction.text2-1"),
    },
    {
      img: "/images/card2.png",
      text1: t("instruction.text1-2"),
      text2: t("instruction.text2-2"),
    },
    {
      img: "/images/card3.png",
      text1: t("instruction.text1-3"),
      text2: t("instruction.text2-3"),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "baseline",
        textAlign: "center",
        bgcolor: "secondary.light",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src="/icons/CurvyLines.png"
        alt="curvy lines"
        sx={{
          pointerEvents: "none",
          display: "flex",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          zIndex: 1,
        }}
      />
      <Container
        sx={{
          my: 4,
          alignItems: "center",
          textAlign: "center",
          zIndex: 2,
        }}
      >
        <Grid item>
          <Typography variant="h4" marked="center" component="h2">
            {t("instruction.title")}
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
          }}
        >
          <Grid item xs={12} md={6} zIndex={100}>
            <Box component="img" src="/images/Main.png" alt="Main" sx={image} />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: { xs: -3, md: 8 },
              mb: 1,
              ml: { sm: 1, md: -11 },
              alignItems: { xs: "center", sm: "baseline" },
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <InstructionCard items={cards} />
          </Grid>
        </Grid>

        {/* <Button
          color="secondary"
          size="large"
          variant="contained"
          sx={{ mt: 8, zIndex: 100 }}
        >
          One more button
        </Button> */}
      </Container>
    </Box>
  );
}

export default Instruction;
