import React, { useState } from "react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { useMyContext } from "../../../MyContext";

function Video({ buttonTitle, youtubeVideoId = "hfyy2-dechg" }) {
  // const youtubeVideoId = "TwXMribt5PY";
  const { setLang, lang, isMobile } = useMyContext();
  const [isVideoVisible, setVideoVisible] = useState(false);
  const width = isMobile ? "350" : "600";
  const l = isMobile ? "280" : "340";
  const { t } = useTranslation();
  const translatedButtonTitle = buttonTitle || t("video.play");

  const showVideo = () => setVideoVisible(true);

  return (
    <Box
      style={{
        position: "relative",
        height: "50vh",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isVideoVisible && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Button
            color="secondary"
            size="large"
            variant="contained"
            sx={{ mt: 8, zIndex: 100 }}
            onClick={showVideo}
            startIcon={<PlayCircleOutlineIcon />}
          >
            {translatedButtonTitle}
          </Button>
        </div>
      )}

      {isVideoVisible && (
        <div>
          <iframe
            width={width}
            height={l}
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      )}
    </Box>
  );
}

export default Video;
