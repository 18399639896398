exports.returnPolicy = {
  ua: {
    title: "Політика повернення коштів Marine Survey для рекламодавців",
    subtitle1:
      "В Marine Survey ми прагнемо забезпечити найкращий можливий досвід для наших користувачів та рекламодавців. Якщо з будь-якої причини ви не задоволені розміщенням вашої реклами на нашій платформі Margram, ми пропонуємо процедуру повернення коштів за наступних умов:",
    text1: null,
    subtitle2: "Умови повернення коштів",
    subtitle2bold: "1. Запит на повернення:",
    ul2: [
      "Рекламодавець може подати запит на повернення коштів протягом 14 днів з моменту оплати реклами.",
      "Щоб подати запит на повернення, будь ласка, зв'яжіться з нашою службою підтримки за адресою support@mibridgehelper.com або через форму зворотного зв'язку на нашому веб-сайті.",
    ],
    subtitle4bold: "2. Причини повернення:",
    ul4: [
      "Повернення коштів можливе у випадку технічних проблем з рекламою, які не були вирішені нашою командою.",
      "Якщо реклама була видалена через порушення умов та правил платформи, повернення коштів не передбачається.",
    ],
    subtitle5bold: "3. Процес розгляду:",
    ul5: [
      "Після отримання запиту на повернення, наша команда розгляне його протягом 7 робочих днів.",
      "Ми можемо запитати додаткову інформацію для більш детального розгляду вашого запиту.",
    ],
    subtitle6bold: "4. Рішення про повернення:",
    ul6: [
      "Якщо повернення коштів схвалено, гроші будуть повернуті на той же спосіб оплати, який був використаний для платежу.",
      "Обробка повернення може зайняти до 14 робочих днів залежно від вашого банку або платіжного процесора.",
    ],
    subtitle7bold: "5. Винятки:",
    ul7: [
      "Часткові повернення коштів не передбачені. Повернення здійснюватиметься лише на повну вартість оплаченої реклами.",
      "Повторні запити на повернення коштів від одного і того ж рекламодавця розглядатимуться в індивідуальному порядку, і ми залишаємо за собою право відмовити в поверненні коштів у разі зловживання процесом.",
    ],
    text7a:
      "Ми цінуємо вашу співпрацю і прагнемо зробити роботу з нашою платформою максимально комфортною. Якщо у вас виникнуть питання або проблеми, не соромтеся звертатися до нашої служби підтримки.",
    subtitle9bold: "З повагою,",
    subtitle10bold: "Команда Marine Survey",
  },
  en: {
    title: "Marine Survey Refund Policy for Advertisers ",
    subtitle1:
      "At Marine Survey, we are committed to providing the best possible experience for our users and advertisers. If for any reason you are not satisfied with the placement of your advertisement on our Margram platform, we offer a refund procedure subject to the following conditions:",
    text1:
      "At Marine Survey, we are committed to providing the best possible experience for our users and advertisers. If for any reason you are not satisfied with the placement of your advertisement on our Margram platform, we offer a refund procedure subject to the following conditions:",
    subtitle2: "Refund Terms",
    subtitle2bold: "1. Return Request:",
    ul2: [
      "The advertiser may submit a refund request within 14 days of payment for the advertisement.",
      "To submit a return request, please contact our support team at support@mibridgehelper.com or via the contact form on our website.",
    ],
    subtitle4bold: "2. Reasons for return:",
    ul4: [
      "Refunds are possible in case of technical problems with advertising that were not resolved by our team.",
      "If an ad was removed due to a violation of the platform's terms and conditions, no refund will be provided.",
    ],
    subtitle5bold: "3. Review process:",
    ul5: [
      "Once a return request is received, our team will review it within 7 business days.",
      "We may request additional information to consider your request in more detail.",
    ],
    subtitle6bold: "4 . Return solution:",
    ul6: [
      "If the refund is approved, the money will be returned to the same payment method that was used for payment.",
      "Refund processing may take up to 14 business days depending on your bank or payment processor.",
    ],
    subtitle7bold: "5 . Exceptions: ",
    ul7: [
      "Partial refunds are not available. Refunds will only be made for the full cost of advertising paid.",
      "Repeated refund requests from the same advertiser will be reviewed on a case-by-case basis and we reserve the right to refuse refunds if the process is abused.",
    ],

    text7a:
      "We value your cooperation and strive to make working with our platform as comfortable as possible. If you have any questions or problems, don't hesitate to contact our support team.",

    subtitle9bold: "Sincerely, ",
    subtitle10bold: "Marine Survey Team",
  },
};
