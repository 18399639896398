import React from "react";
import { motion } from "framer-motion";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import Typography from "../../components/Typography";

function CollaborationProcess() {
  const theme = useTheme();
  const { t } = useTranslation();
  const collaboration = t("partners.collaboration", { returnObjects: true });

  const handleEmailClick = () => {
    window.location.href = `mailto:${Object.values(collaboration.process)[0]}`;
  };

  const getIcon = (index) => {
    switch (index) {
      case 0:
        return <EmailIcon />;
      case 1:
        return <ChatIcon />;
      case 2:
        return <RocketLaunchIcon />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ my: 5, maxWidth: 800 }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", mb: 4, color: "warning.main" }}
      >
        {collaboration.title}
      </Typography>
      <List>
        {Object.entries(collaboration.process).map(
          ([step, description], index) => (
            <motion.div
              key={step}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 1 * index }}
            >
              <ListItem
                sx={{
                  mb: 3,
                  background: "#c21d64",
                  borderRadius: 2,
                  boxShadow: 3,
                  "&:hover": {
                    boxShadow: 6,
                    transform: "scale(1.02)",
                    transition: "all 0.3s ease-in-out",
                  },
                }}
              >
                <ListItemIcon sx={{ color: theme.palette.secondary.main }}>
                  {getIcon(index)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Step {index + 1}: {step}
                    </Typography>
                  }
                  secondary={
                    index === 0 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<EmailIcon />}
                        onClick={handleEmailClick}
                        sx={{ mt: 1 }}
                      >
                        {description}
                      </Button>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(255,255,255,0.7)" }}
                      >
                        {description}
                      </Typography>
                    )
                  }
                />
              </ListItem>
            </motion.div>
          ),
        )}
      </List>
    </Box>
  );
}

export default CollaborationProcess;
