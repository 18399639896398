/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { ListItem, Badge } from "@mui/material";
import Typography from "../../components/Typography";
import { ModalItems, products } from "../../../CONTACTS";
import ProductModal from "./ProductModal";

import GradientBorder from "../../components/GradientBorderBox";

const Icon = styled("img")(({ theme }) => ({
  width: "30px",
  bottom: 0,
  position: "relative",
  "&:hover": {
    animation: "none",
    transform: "rotate(360deg)",
    transition: "transform 0.5s",
  },
}));

function Product() {
  const controls = useAnimation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleTooltipOpen = (index) => {
    setTooltipOpen(index);
  };

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const [selectedProductId, setSelectedProductId] = useState(null);

  const openProductModal = (productId) => {
    setSelectedProductId(productId);
  };

  const closeProductModal = () => {
    setSelectedProductId(null);
  };
  return (
    <Box
      component="section"
      sx={{ bgcolor: "primary.main", py: 4, color: "white" }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ delay: 0.5 }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 3, color: "white" }}
          >
            {t("product.1")}
            <span style={{ color: "#dfc25f", marginLeft: "8px" }}>
              {" "}
              {t("product.2")}
            </span>
          </Typography>
        </motion.div>

        <Grid container spacing={2}>
          {products[lang].map((item, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={controls}
                transition={{ delay: i * 0.1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    src="./icons/arrow3.png"
                    alt="Arrow Marine Survey"
                    onClick={() => openProductModal(item.id)}
                    sx={{ cursor: "pointer" }}
                  />
                  <ListItem>
                    {/* {item && (
                      <Tooltip
                        title="Click to see more"
                        arrow
                        open={tooltipOpen === i}
                      > */}
                    {item.new ? (
                      <Badge
                        badgeContent="NEW"
                        color="secondary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <GradientBorder>
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{
                              cursor: "pointer",
                              p: 1,
                              color: "white",
                              transition: "transform 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                            }}
                            // onHover={() => handleTooltipOpen(i)}
                            // onMouseLeave={handleTooltipClose}
                            onClick={() => openProductModal(item.id)}
                          >
                            {item.title}
                          </Typography>
                        </GradientBorder>
                      </Badge>
                    ) : (
                      <Typography
                        variant="h6"
                        align="left"
                        sx={{
                          cursor: "pointer",
                          color: "white",
                          transition: "transform 0.3s ease",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                        // onHover={() => handleTooltipOpen(i)}
                        // onMouseLeave={handleTooltipClose}
                        onClick={() => openProductModal(item.id)}
                      >
                        {item.title}
                      </Typography>
                    )}

                    {/* </Tooltip>
                    )} */}
                  </ListItem>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
      {selectedProductId && (
        <ProductModal
          productId={selectedProductId}
          onClose={closeProductModal}
          lang={lang}
        />
      )}
    </Box>
  );
}

export default React.memo(Product);
