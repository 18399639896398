import React from "react";
import { useMediaQuery } from "@mui/material";
import Screens from "./Screens";
import Main from "./Main";
import ListOne from "./ListOne";
import ListTwo from "./ListTwo";
import ListThree from "./ListThree";
import Video from "../Main/Video";

function index() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <div
      style={{
        background: "radial-gradient(circle,  #80A4B7,white)",
        padding: "2px",
        // borderRadius: "10px",
      }}
    >
      <Main />
      <ListOne isSmallScreen={isSmallScreen} />
      <ListTwo isSmallScreen={isSmallScreen} />
      <ListThree />
      <Screens />
      <Video youtubeVideoId="4ZKbZ7ee1VI" />
    </div>
  );
}

export default index;
