/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
export function generateImageObjects(count) {
  const imageObjects = [];

  for (let i = 1; i <= count; i++) {
    const imageUrl = `./images/best/${i}.png`;
    const altText = `marine-survey-best-app-${i}`;

    const imageObject = {
      url: imageUrl,
      alt: altText,
    };

    imageObjects.push(imageObject);
  }

  return imageObjects;
}

export function findPreferredLanguage() {
  const preferredLanguages = navigator.languages;
  console.log("navigator", navigator);
  console.log("PrefLanguages", preferredLanguages);

  const appLanguages = ["ua", "en"];

  let lastMatchedLanguage = null;

  for (const language of preferredLanguages) {
    const normalizedLanguage = language.toLowerCase();

    const matchedLanguage = appLanguages.find((appLanguage) =>
      normalizedLanguage.includes(appLanguage),
    );

    if (matchedLanguage) {
      lastMatchedLanguage = matchedLanguage;
    }
  }
  // If no match is found, return a default language (e.g., 'en' for English).
  return lastMatchedLanguage || "en";
}
