/* eslint-disable react/no-array-index-key */
import React from "react";
import { Container, Grid, Link } from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import { CONTACTS } from "../../../CONTACTS";

function CrewingPage() {
  const { t } = useTranslation();
  const apple = "/icons/app3.png";
  const google = "/icons/google.png";

  const controlsLeft = {
    hidden: { opacity: 0, x: -661, y: 0 },
    visible: { opacity: 1, x: 0, y: 105 },
  };
  const controlsRight = {
    hidden: { opacity: 0, x: 661, y: 0 },
    visible: { opacity: 1, x: 0, y: -105 },
  };

  return (
    <Container>
      <Typography
        variant="h2"
        marked="center"
        component="h1"
        sx={{ mt: 5, mb: 3, textAlign: "center", fontSize: "60px" }}
      >
        {t("crewing.1")}
      </Typography>
      <Typography
        variant="h6"
        component="p"
        align="center"
        color="primary.dark"
      >
        {t("crewing.2")}
      </Typography>
      <Grid
        container
        sx={{
          display: "grid",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
          <motion.div
            variants={controlsLeft}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5 }}
          >
            <Link href={CONTACTS.google} target="_blank">
              <img src={google} alt="icons" style={{ width: "280px" }} />
            </Link>
          </motion.div>
          <motion.div
            variants={controlsRight}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5 }}
          >
            <Link href={CONTACTS.apple} target="_blank">
              <img src={apple} alt="icons" style={{ width: "280px" }} />
            </Link>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CrewingPage;
