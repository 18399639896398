import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { styled } from "@mui/system";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { margram } from "../../../CONTACTS";
import Button from "../../components/Button";
import Typography from "../../components/Typography";

const NumberSlide = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "50px",
  color: "#fff",
  fontWeight: 500,
  height: "300px",
  maxHeight: "100vh",
  width: "200vh",
}));

const NumberSlide1 = styled(NumberSlide)(({ theme }) => ({
  background:
    "linear-gradient(128deg,  rgba(223, 194, 95, 1) 20%, rgba(227, 219, 164, 1) 90%)",
}));
const NumberSlide2 = styled(NumberSlide)(({ theme }) => ({
  background:
    "linear-gradient(128deg,rgba(28, 100, 196, 1)10%, rgba(64, 175, 255, 0.71) 70%)",
}));

const NumberSlide3 = styled(NumberSlide)(({ theme }) => ({
  background:
    "linear-gradient(128deg,rgba(74, 103, 138, 1), rgba(64, 175, 255, 0.71) 70%)",
}));

function Margram() {
  const { t, i18n } = useTranslation();
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ],
  );

  return (
    <div ref={sliderRef} className="keen-slider">
      <NumberSlide1 className="keen-slider__slide">
        <SlideOne />
      </NumberSlide1>
      <NumberSlide2 className="keen-slider__slide">
        <SlideTwo />
      </NumberSlide2>
      {/* <NumberSlide3 className="keen-slider__slide">
        {" "}
        <SlideThree />
      </NumberSlide3> */}
    </div>
  );
}

export default React.memo(Margram);

function SlideOne() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src="/images/margram/pattern1.png"
        height="200px"
        weight="150px"
        alt="pattern"
        sx={{ position: "absolute", left: 0, bottom: 0 }}
      />
      <Typography
        variant="h4"
        mb={5}
        mt={2}
        sx={{
          fontSize: {
            xs: "1.5rem", // extra small devices
            sm: "1.95rem", // small devices
            md: "2.95rem", // medium devices, default h4 size
            lg: "2.5rem", // large devices
            xl: "3rem", // extra large devices
          },
        }}
      >
        {margram.slogan1[lang]}
      </Typography>
      <Button
        href="/margram"
        sx={{
          padding: 2,
          marginBottom: 5,
          width: "300px ",
        }}
        gradientStyle
      >
        <span> {margram.callToAction[lang]}</span>
      </Button>
    </Container>
  );
}
function SlideTwo() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        component="img"
        src="/images/margram/pattern5.png"
        height="300px"
        weight="150px"
        alt="pattern"
        sx={{ position: "absolute", right: -50, bottom: -70 }}
      />
      <Typography
        variant="h4"
        mb={5}
        color="white"
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "1.95rem",
            md: "2.95rem",
            lg: "2.5rem",
            xl: "3rem",
          },
        }}
      >
        {margram.slogan2[lang]}
      </Typography>
      <Button
        href="/margram"
        sx={{
          padding: 2,
          marginBottom: 5,
          width: "300px ",
        }}
        gradientStyle
      >
        <span>{margram.callToAction[lang]}</span>
      </Button>
    </Container>
  );
}

// function SlideThree() {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         textAlign: "center",
//         alignItems: "center",
//       }}
//     >
//       <Typography variant="h4" mb={5} color="white">
//         {margram.slogan}
//       </Typography>
//       <Button
//         href="/margram"
//         sx={{
//           padding: 2,
//           marginBottom: 5,
//           width: "300px ",
//         }}
//         gradientStyle
//       >
//         <span>Check it out</span>
//       </Button>
//     </Box>
//   );
// }
