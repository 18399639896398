import axios from "axios";
import emailjs from "@emailjs/browser";

const options = {
  publicKey: "LYO2dI_nDLy7JpuT_",
  // Do not allow headless browsers
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    // list: ["foo@emailjs.com", "bar@emailjs.com"],
    // The variable contains the email address
    watchVariable: "userEmail",
  },
  limitRate: {
    // Set the limit rate for the application
    id: "app",
    // Allow 1 request per 10s
    throttle: 10000,
  },
};

const sendEmail = async (formData) => {
  emailjs.init(options);
  const params = {
    from_name: "Stas", // Add a from_name parameter if required
    from_email: formData.email, // Ensure this matches your template variable
    to_email: "support@mybridgehelper.com",
    title: formData.title, // Ensure this matches your template variable
    message: formData.message, // Ensure this matches your template variable
    reply_to: formData.email, // Ensure this matches your template variable if required
  };
  const serviceId = "service_vwdmaqq";
  const templateId = "template_k0wp24s";
  try {
    // Ensure service ID and template ID match your EmailJS setup
    const response = await emailjs.send(serviceId, templateId, params);
    console.log("Email sent successfully!", response);
    return response; // Optionally return the response object for further processing
  } catch (error) {
    console.error("Failed to send email", error);
    throw error; // Consider how you want to handle errors
  }
  // return axios.post( url, formData );
};

export default sendEmail;
