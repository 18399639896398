import React from "react";
import { Grid, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import { margram, CONTACTS } from "../../../CONTACTS";

function MargramMain() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <>
      <Typography
        variant="h2"
        marked="center"
        component="h1"
        sx={{ mt: 5, mb: 3, textAlign: "center", fontSize: "60px" }}
      >
        Margram
      </Typography>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Container>
            <Typography
              variant="h4"
              color="warning.main"
              sx={{ mb: "2rem" }}
              gutterBottom
            >
              {margram.slogan1[lang]}
            </Typography>
            {/* <p>Варианты слоганов : </p>
          <Typography variant="overline">
            {" "}
            Navigate Your Professional Network
          </Typography>
          <Typography variant="overline">
            {" "}
            Your Compass for Marine Networking
          </Typography>
          <Typography variant="overline">
            {" "}
            Connect Across Oceans, Ports, and Crews
          </Typography> */}
          </Container>
          <Button
            href={CONTACTS.appleFree}
            sx={{
              padding: "20px",
              paddingLeft: "45px",
              paddingRight: "45px",
              margin: "5px",
              width: "300px ",
            }}
            target="_blank"
            rel="noopener noreferrer"
            gradientStyle
          >
            <span>Apple Store</span>
          </Button>
          <Button
            href={CONTACTS.google}
            sx={{
              padding: "20px",
              paddingLeft: "45px",
              paddingRight: "45px",
              margin: "5px",
              width: "300px ",
            }}
            target="_blank"
            rel="noopener noreferrer"
            gradientStyle
          >
            <span>Google Play</span>
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <img
            src="/images/margram/header-img.png"
            alt=""
            width="100%"
            height="400px"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MargramMain;
