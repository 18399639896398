import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const ButtonRoot = styled(MuiButton)(({ theme, size, gradientStyle }) => ({
  textTransform: "uppercase",
  borderRadius: "30px",
  position: "relative",
  overflow: "hidden",
  color: "#ffffff",
  background: theme.palette.secondary.dark,
  fontWeight: 600,
  transition: "color 0.3s ease",
  border: "1px solid rgba(248, 248, 248, 0.7)",
  zIndex: 111,

  "&:hover": {
    border: "none",
  },

  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "-100%",
    width: "100%",
    height: "100%",
    background: `linear-gradient(to left, #dfc25f, #1c64c4)`,
    transition: "left 0.4s ease",
    zIndex: -1,
  },

  "&:hover:before": {
    left: 0,
  },

  "&:active:before": {
    right: "100%",
    left: 0,
  },
  boxShadow: "none",
  "&:active, &:focus": {
    boxShadow: "none",
  },

  ...(gradientStyle && {
    backgroundColor: "#c21d64",
    backgroundImage: "linear-gradient(to left, #4a678a, #95ccfc, #c21d64)",
    color: "#fff",
    "& svg": {
      fill: "#fff",
    },
    "&:hover": {
      background: "#0f0f1e",
      border: "none",
      color: "#fff",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      background: "#0f0f1e",
      zIndex: -1,
    },
  }),
  ...(size === "small" && {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  }),
  ...(size === "large" && {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  }),
}));

// See https://mui.com/guides/typescript/#usage-of-component-prop for why the types uses `C`.
function Button(props) {
  return <ButtonRoot {...props} />;
}

export default Button;
