import React, { useState, useEffect } from "react";
import { styled, keyframes, useTheme } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AnimatedLogo = styled("img")({
  width: "100px",
  borderRadius: "50px",
  animation: `${spin} 2s infinite linear`,
  transition: "opacity 1s ease-in-out",
});

const LoadingSpinnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "primary.dark",
});

function LoadingSpinner() {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => (prev + 5) % 100);
    }, 100);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the duration as needed

    return () => clearTimeout(loadingTimer);
  }, []);

  const logo = "/favicon.png";

  return (
    <LoadingSpinnerContainer>
      <AnimatedLogo
        style={{
          zIndex: 999,
        }}
        src={logo}
        alt="logo"
      />
      {isLoading && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            width: "80%",
            borderRadius: "10px",
            marginTop: "-50px",
            height: 1.2,
            marginBottom: "10px",
            "& .MuiLinearProgress-bar": {
              backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark}, ${theme.palette.warning.main}, ${theme.palette.secondary.main})`,
            },
          }}
        />
      )}
    </LoadingSpinnerContainer>
  );
}

export default LoadingSpinner;
