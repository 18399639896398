import React, { useState } from "react";
import {
  Box,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import { CONTACTS } from "../../../CONTACTS";
import sendEmail from "../../../hooks/sendEmail";

export default function RequestForm({
  setOpen,
  selectedTitle,
  setSelectedTitle,
  handleEmailClick,
  setSuccessfullySent,
}) {
  const del = {
    en: "Please delete my account in APP Marine Survey Free",
    ua: "Прошу видалити мій аккаунт на APP Marine Survey Free",
  };

  const emailTitlestart = "Message from Marine Survey App : ";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [message, setMessage] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTitleChange = (event) => {
    setSelectedTitle(event.target.value);
    if (event.target.value === "deleteAccount") setMessage(del[lang]);
  };

  const handleMessageTitleChange = (event) => {
    setSelectedTitle(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      email: event.target.email.value,
      title: `${emailTitlestart}${selectedTitle}`,
      message,
    };

    sendEmail(formData)
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          // If the response indicates success
          setOpen(true);
          setSuccessfullySent(true);
        } else {
          // If the response indicates failure
          setOpen(true);
          setSuccessfullySent(false);
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle any error that occurred during the request
        setOpen(true);
        setSuccessfullySent(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        zIndex: 1,
        justifyContent: "center",
        bgcolor: "rgba(124, 174, 229, 0.85)",
        color: "white",
        py: isMobile ? 3 : 8,
        px: isMobile ? 3 : 8,
        mx: isMobile ? -1 : 0,
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          maxWidth: 500,
          minWidth: 250,
          my: 1,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h4" gutterBottom>
          {t("contact.1")}
        </Typography>
        <TextField
          type="email"
          name="email"
          required
          size="medium"
          placeholder="Your email"
          sx={{ width: "100%", mt: 3, mb: 1 }}
        />
        <Select
          sx={{
            width: "100%",
            background: "white",
            borderRadius: "4px",
          }}
          value={selectedTitle}
          onChange={handleTitleChange}
          displayEmpty
          id="title"
          // input={<TextField id="title" />}
          onMouseEnter={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
        >
          <MenuItem value="" disabled>
            {t("contact.4")}
          </MenuItem>
          <MenuItem value="deleteAccount">{del[lang]}</MenuItem>
          <MenuItem value="other">{lang === "en" ? "Other" : "Інше"}</MenuItem>
        </Select>
        {/* {selectedTitle === "deleteAccount" && (
            <Tooltip
              title="Your account will be completely deleted from the app server within 24 hours after the request and no data will be saved"
              arrow
              open={isTooltipOpen}
            />
          )} */}
        {selectedTitle === "deleteAccount" && (
          <Typography variant="body1" component="p" sx={{ mx: 3 }}>
            Your account will be completely deleted from the app server within
            24 hours after the request and no data will be saved
          </Typography>
        )}

        {selectedTitle !== "deleteAccount" && selectedTitle !== "" && (
          <TextField
            required
            noBorder
            value={selectedTitle}
            name="selectedTitle"
            onChange={handleMessageTitleChange}
            placeholder="Message Title"
            size="medium"
            sx={{ width: "100%", my: 1 }}
          />
        )}

        <TextField
          required={selectedTitle !== "deleteAccount"}
          noBorder
          name="message"
          onChange={handleMessageChange}
          multiline
          placeholder="Your message"
          size="medium"
          sx={{ width: "100%", my: 1 }}
        />

        <Button
          type="submit"
          size="large"
          color="secondary"
          variant="contained"
          endIcon={<SendIcon />}
          sx={{
            my: 2,
            p: 2,
            zIndex: 100,
            maxWidth: "200px",
            width: "100%",
            color: "black",
          }}
        >
          {t("contact.1")}
        </Button>
        {isMobile && (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h4" component="h4" gutterBottom sx={{ mt: 5 }}>
              {t("contact.2")}
            </Typography>
            <Button
              color="secondary"
              size={isMobile ? "small" : "large"}
              variant="contained"
              onClick={handleEmailClick}
              sx={{
                p: 2,
                mb: 2,
                zIndex: 100,
                width: "103%",
                color: "black",
              }}
              endIcon={<SendIcon />}
            >
              Send email to {CONTACTS.email}
            </Button>
            <Typography variant="subtitle1" sx={{ my: 2 }}>
              {t("contact.3")}
            </Typography>
            <Box
              component="img"
              src="/icons/productBuoy.svg"
              alt="getInTouch"
              sx={{ width: 60, bgcolor: "white", borderRadius: "50%" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
