import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/system";

function LinearBar({ progress }) {
  const theme = useTheme();

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        width: "100%",
        mt: -2,
        height: 4.2,
        "& .MuiLinearProgress-bar": {
          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark}, ${theme.palette.warning.main}, ${theme.palette.secondary.main})`,
        },
      }}
    />
  );
}

export default LinearBar;
