import { styled } from "@mui/system";

const StyledIcon = styled("img")(({ theme }) => ({
  width: "30px",
  marginRight: 7,
  position: "relative",
  "&:hover": {
    animation: "none",
    transform: "rotate(360deg)",
    transition: "transform 0.9s",
  },
}));

export default function Icon({ src, alt }) {
  return <StyledIcon src={src} alt={alt} />;
}
