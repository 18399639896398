import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import SendIcon from "@mui/icons-material/Send";
import Typography from "../../components/Typography";
import Snackbar from "../../components/Snackbar";
import Button from "../../components/Button";
import { CONTACTS } from "../../../CONTACTS";
import RequestForm from "./RequestForm";

function ContacForm() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [selectedTitle, setSelectedTitle] = React.useState("");
  const [isSuccessfullySent, setSuccessfullySent] = React.useState(false);
  const snackBarIconBgColor = "secondary.main";
  const handleClose = () => {
    setOpen(false);
  };
  const handleEmailClick = () => {
    window.location.href = `mailto:${CONTACTS.email}`;
  };

  const configureMessage = () => {
    let stringToReturn = "";

    if (isSuccessfullySent) {
      stringToReturn =
        selectedTitle === "deleteAccount"
          ? "Your request for account deletion has been sent."
          : "Your request has been sent.";
    } else {
      stringToReturn =
        selectedTitle === "deleteAccount"
          ? "Your request for account deletion has NOT been sent due to an error."
          : "Your request has NOT been sent due to an error.";
    }

    return stringToReturn;
  };

  return (
    <Container
      component="section"
      sx={{
        mt: -10,
        display: "flex",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        width="100%"
        sx={{ mt: 15, mb: 5, boxShadow: 10 }}
      >
        <RequestForm
          setSuccessfullySent={setSuccessfullySent}
          setOpen={setOpen}
          selectedTitle={selectedTitle}
          setSelectedTitle={setSelectedTitle}
          handleEmailClick={handleEmailClick}
        />
      </Grid>
      <Snackbar
        open={open}
        isError={!isSuccessfullySent}
        closeFunc={handleClose}
        message={configureMessage()}
      />
    </Container>
  );
}

export default ContacForm;
